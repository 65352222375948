const FETCH_MEDICAL_ALLERGY_REQUEST = 'FETCH_MEDICAL_ALLERGY_REQUEST';
const FETCH_MEDICAL_ALLERGY_SUCCESS = 'FETCH_MEDICAL_ALLERGY_SUCCESS';
const FETCH_MEDICAL_ALLERGY_FAILURE = 'FETCH_MEDICAL_ALLERGY_FAILURE';
const ADD_MEDICAL_ALLERGY = 'ADD_MEDICAL_ALLERGY';
const UPDATE_MEDICAL_ALLERGY = 'UPDATE_MEDICAL_ALLERGY';

export {
    FETCH_MEDICAL_ALLERGY_REQUEST,
    FETCH_MEDICAL_ALLERGY_SUCCESS,
    FETCH_MEDICAL_ALLERGY_FAILURE,
    ADD_MEDICAL_ALLERGY,
    UPDATE_MEDICAL_ALLERGY
};
