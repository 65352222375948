/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment, Typography, Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import ReCAPTCHA from 'react-google-recaptcha';
import { appMessages } from 'Constants/appMessage';
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from 'utils/LoadingContent';
import { getdata, postDataApi } from 'utils/apiCalling';
import { constants, ipDetailsURL, sekretKey } from 'Constants/constants';
import { useDispatch } from 'react-redux';
// icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { MenuItem } from '@material-ui/core';
import { setUserInformation } from 'store/LoginUser/LoginUserAction';
import LocalStorage from 'utils/LocalStorage';

// ----------------------------------------------------------------------

export default function ViewReportLoginForm() {
    const navigate = useNavigate();
    const loginDispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    // const [termsTick, settermsTick] = useState(false);
    const [validUser, setValidUser] = useState(false);
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [country, setCountry] = useState([]);

    const [ipDetails, setIPDetails] = useState(null);

    useEffect(() => {
        getIPdetails();
        getAllCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllCountry = async () => {
        const response = await getdata(constants.getAllCountries, false);
        if (response.status === 200) setCountry(response.data);
        else setCountry([]);
    };

    const getIPdetails = async () => {
        const resp = await getdata(ipDetailsURL, false);
        if (resp.status === 200) {
            if (resp.data && resp.data.hasOwnProperty('country_code_iso3')) {
                setIPDetails(() => resp.data);
                formik.setFieldValue('defaultType', 'phone');
                formik.setFieldValue('country', resp.data.country_calling_code);

            }
        }
    };

    const [counter, setCounter] = useState(59);
    useEffect(() => {
        const timer = counter > 0 && validUser && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter, validUser]);

    const LoginSchema = Yup.object().shape({
        phone: Yup.string().when('defaultType', {
            is: (defaultType) => defaultType === 'phone',
            then: Yup.string().matches(appMessages.phoneRegExp, appMessages.validPhone).required(appMessages.phoneRequired),
            otherwise: Yup.string()
        }),
        country: Yup.string().when('defaultType', {
            is: (defaultType) => defaultType === 'phone',
            then: Yup.string().required(appMessages.required),
            otherwise: Yup.string()
        }),
        passwordOtp: Yup.string().when('isRequiredOtp', {
            is: (isRequiredOtp) => isRequiredOtp,
            then: Yup.string().required(appMessages.OtpRequired),
            otherwise: Yup.string()
        }),
    });

    const formik = useFormik({
        initialValues: {
            phone: '',
            defaultType: 'phone',
            isRequiredOtp: false,
            passwordOtp: '',
            remember: true,
            country: '',
        },

        validationSchema: LoginSchema,

        onSubmit: (values) => {
            if (values.isRequiredOtp) {
                signInUser(values);
            } else {
                sendOtp(values)
            }
        }
    });

    // OTP Send function
    const sendOtp = async (LoginData) => {
        addLoader();

        let jsonData = {
            phone: (LoginData.phone).toString(),
            // country_id: 1,
            country: LoginData.country
        };

        const response = await postDataApi(constants.sendPhoneOtp, jsonData);
        removeLoader();
        if (response && response.data.code === 200 && response.data.status === 'success') {
            addSuccessMessage(response.data.message);
            setValidUser(() => true);
            setCounter(59);
            formik.setFieldValue('isRequiredOtp', true)
        } else addErrorMessage(response.data.message);
    };

    // after OTP SignIn user
    const signInUser = async (LoginData) => {
        if (!isCaptcha) {
            addErrorMessage('Please tick captcha');
        } else {
            addLoader();
            let jsonData = {
                phone: (LoginData.phone).toString(),
                // country_id: 1,
                country: LoginData.country,
                otp: LoginData.passwordOtp
            };

            const response = await postDataApi(constants.verifyPhoneOtp, jsonData);
            removeLoader();
            if (response.status === 200) {
                console.log({ response })
                LocalStorage.setToken(response.data.data.access_token);
                LocalStorage.setLocalRefreshToken(response.data.data.refresh_token);
                loginDispatch(setUserInformation(LocalStorage.getUserDetails()));
                // sessionStorage.setItem('userSession', JSON.stringify(response.data));
                navigate('/app');
                window.location.reload();
            } else {
                removeLoader();
                addErrorMessage('Invalid Credentials!');
            }
        }
    };

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    // const handleChange = (event) => {
    //     settermsTick(event.target.checked);
    // };
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate>
                    <Typography variant="h2" gutterBottom sx={{ mt: 3, mb: 3 }}>
                        View All Your Test Reports
                    </Typography>
                    <div style={{ textAlign: 'center' }}>

                        {/* <img src={loginrReport} alt='login' width={100} height={100}></img> */}
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={4} lg={4} sm={4} xs={4}>
                            <TextField
                                fullWidth
                                required
                                select
                                disabled={values.isRequiredOtp}
                                {...getFieldProps('country')}
                                error={Boolean(touched.country && errors.country)}
                                helperText={touched.country && errors.country}
                            >
                                {country.sort().map((item) => (
                                    <MenuItem key={item.id} value={item.country_phone_code}>
                                        {item.country_phone_code}
                                    </MenuItem>
                                ))}
                            </TextField>{' '}
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={8}>
                            <TextField
                                fullWidth
                                autoComplete="phone number"
                                type="number"
                                label="Enter patient Phone Number"
                                {...getFieldProps('phone')}
                                disabled={values.isRequiredOtp}
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                            />
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }}>
                            {/* <ReCAPTCHA style={{ width: "100%" }} sitekey={sekretKey.googleCaptcha} onChange={() => setIsCaptcha(true)} /> */}

                            {validUser && (
                                <Stack spacing={3}>
                                    <TextField
                                        fullWidth
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        label="Enter one time password"
                                        {...getFieldProps('passwordOtp')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleShowPassword} edge="end">
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={Boolean(touched.passwordOtp && errors.passwordOtp)}
                                        helperText={touched.passwordOtp && errors.passwordOtp}
                                    />
                                    <Box mt={3}>
                                        {counter > 0 && (
                                            <>
                                                <Typography>
                                                    One time password (OTP) has been sent to your pnone number{' '}
                                                    <strong>{values.phone}</strong>.
                                                </Typography>
                                                <Typography fontWeight={500} align="center" color="textSecondary">
                                                    Resend OTP in <span style={{ color: 'green', fontWeight: 'bold' }}> 00:{counter}</span>{' '}
                                                </Typography>
                                            </>
                                        )}
                                        {counter === 0 && (
                                            <Typography
                                                align="left"
                                                onClick={() =>
                                                    sendOtp({
                                                        phone: values.phone,
                                                    })
                                                }
                                            >
                                                <span style={{ marginLeft: '5px', cursor: 'pointer', color: ' #2196F3' }}>Resend OTP</span>
                                            </Typography>
                                        )}
                                    </Box>

                                    <ReCAPTCHA sitekey={sekretKey.googleCaptcha} onChange={() => setIsCaptcha(true)} />
                                </Stack>
                            )}
                        </Grid>
                        <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => handleSubmit()}
                                endIcon={validUser ? '' : <ArrowRightAltIcon fontSize="large" />}
                            >
                                {validUser ? 'Submit' : 'Continue'}
                            </Button>
                        </Grid>
                    </Grid>

                    {/* <Stack direction="column" alignItems="flex-end" justifyContent="space-between" sx={{ my: 2 }}>
                        <Link component={RouterLink} variant="subtitle2" to="/user/forgotPassword">
                            Forgot password?
                        </Link>
                    </Stack> */}

                    {/* <AuthSocial label="log in"></AuthSocial> */}
                    {/* <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                        Don’t have an account?&nbsp;
                        <Link variant="subtitle2" underline="none" component={RouterLink} to="/register">
                            Get started
                        </Link>
                    </Typography> */}
                </Form>
                {/* {termsTick && <TermsAndCondition />} */}
            </FormikProvider>
        </>
    );
}
