import * as constant from './serviceOrderHistoryTypes';

const initialState = {
    loading: false,
    serviceOrderHistory: [],
    error: ''
};

const serviceOrderHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.FETCH_SERVICE_ORDER_HISTORY_REQUEST:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_SERVICE_ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceOrderHistory: action.payload,
                error: ''
            };

        case constant.FETCH_SERVICE_ORDER_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                serviceOrderHistory: [],
                error: action.payload
            };

        default:
            return state;
    }
};
export default serviceOrderHistoryReducer;
