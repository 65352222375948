import FormDialog from 'components/FormDialog/FormDialog';
import mainMenu from 'menu-items/main-menu';
import ReactDOM from 'react-dom';
import config from 'config';
import instantShareMenu from 'menu-items/intant-share-menu';
import shareMenu from 'menu-items/share-menu';
import publicRoutesMenu from 'menu-items/publicRoutesMenu';
import LocalStorage from 'utils/LocalStorage';
import { Provider } from 'react-redux';
import { store } from 'store';
import CustomConfirmationPopup from 'components/CustomConfirmationPopup/CustomConfirmationPopup';
import moment from 'moment';

export function formDialog(component, fullscreen, title, width, isMedicalHitory = false, action) {
    ReactDOM.render(
        <Provider store={store}>
            <FormDialog
                fullScreen={fullscreen}
                maxSize={width}
                title={title}
                isMedicalHitory={isMedicalHitory}
                Component={component}
                action={action}
            />
        </Provider>,
        document.getElementById('form')
    );
}

export function closeDialog() {
    ReactDOM.unmountComponentAtNode(document.getElementById('form'));
}

export function confirmBox(message, handleAction, title = 'Delete Confirmation', yesName = 'Confirm', noName = 'Cancel') {
    ReactDOM.render(
        <CustomConfirmationPopup
            title={title}
            message={message}
            isYes
            isNo
            yesName={yesName}
            noName={noName}
            handleAction={handleAction}
        />,
        document.getElementById('confirm-dialog')
    );
}

// check user Role and isLoggedIn
export function getUserRole() {
    const authRole = config.ROLES;
    const ROLES = {
        patient: authRole[0].roleId,
        doctor: authRole[1].roleId,
        share: authRole[2].roleId,
        instant_share: authRole[3].roleId
    };
    const data = LocalStorage.getUserDetails();
    switch (data?.role_id) {
        case ROLES.patient:
            return 'patient';
        case ROLES.doctor:
            return 'doctor';
        case ROLES.instant_share:
            return 'instant_share';
        case ROLES.share:
            return 'share';
        default:
            return 'home';
    }
}
/**
 *
 * @returns
 */
export function getDashboardMenu() {
    const data = getUserRole();
    switch (data) {
        case 'patient':
            return mainMenu;
        // case ROLES.doctor:
        //     return 'doctor';
        case 'instant_share':
            return instantShareMenu;
        case 'share':
            return shareMenu;
        case 'home':
            return publicRoutesMenu;
        default:
            return '';
    }
}

export function getRoleRoute() {
    const data = getUserRole();
    switch (data) {
        case 'patient':
            return '/app/dashboard';
        // case ROLES.doctor:
        //     return 'doctor';
        case 'instant_share':
            return '/instant/instant-access-medical-records';
        case 'share':
            return '/share/view-shared-record-list';
        default:
            return '/home';
    }
}

// scroll top
export function scrollTop() {
    return window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

export function getUserId() {
    const user = LocalStorage.getUserDetails();
    if (user && user.mdrx_id) {
        return user.mdrx_id;
    }
}

export const formatDate = (date) => {
    return moment(date).format('DD MMM, YYYY');
};

export function updateMultipleInList(list, uniqueElement, updateElement) {
    return list.map((item) => {
        if (uniqueElement === item.id) {
            for (const key in updateElement) {
                if (updateElement.hasOwnProperty(key)) {
                    const element = updateElement[key];
                    item[key] = element;
                }
            }
        }
        return item;
    });
}

export function deleteByItemById(list, itemId) {
    return list.filter((item) => item.id !== itemId);
}
