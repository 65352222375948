import { Dialog } from '@material-ui/core';
// import Loader from "react-loader-spinner";
import { CircularProgress } from '@mui/material';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import React from 'react';
import ReactDOM from 'react-dom';
import reactDom from 'react-dom';

export function addLoader() {
    ReactDOM.render(
        <Dialog
            open={true}
            fullWidth
            fullScreen
            PaperComponent="div"
            PaperProps={{
                style: {
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            }}
        >
            <CircularProgress style={{ color: 'darkblue' }} size={80} thickness={4} />
        </Dialog>,
        document.getElementById('loader')
    );
}

export function removeLoader() {
    ReactDOM.unmountComponentAtNode(document.getElementById('loader'));
}

export function addSuccessMessage(message) {
    reactDom.render(
        <CustomSnackbar message={message} color="success" renderElement={document.getElementById('alertmessage')} />,
        document.getElementById('alertmessage')
    );
}
export function addErrorMessage(message) {
    reactDom.render(
        <CustomSnackbar message={message} color="error" renderElement={document.getElementById('alertmessage')} />,
        document.getElementById('alertmessage')
    );
}
export function addWarningMessage(message) {
    reactDom.render(
        <CustomSnackbar message={message} color="warning" renderElement={document.getElementById('alertmessage')} />,
        document.getElementById('alertmessage')
    );
}
export function addInformationMessage(message) {
    reactDom.render(
        <CustomSnackbar message={message} color="info" renderElement={document.getElementById('alertmessage')} />,
        document.getElementById('alertmessage')
    );
}
