import {
    // Card,
    // List,
    // ListItem,
    // ListItemButton,
    // ListItemText,
    TextField,
    Autocomplete,
    InputAdornment,
    createFilterOptions
} from '@mui/material';
// import { Box } from '@mui/system';
// import { styled, alpha } from '@mui/material/styles';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.primary.light),
//     '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25)
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//         marginLeft: theme.spacing(3),
//         width: 'auto'
//     }
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     borderRadius: '8px 0px 0px 8px',
//     background: theme.palette.primary.main
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: ' #FAFBFC',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         border: `1px solid ${theme.palette.primary.light}`,
//         paddingLeft: `calc(2em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         borderRadius: '8px 0px 0px 8px',
//         [theme.breakpoints.up('md')]: {
//             width: '25ch'
//         },
//         '&::placeholder': {
//             textOverflow: 'ellipsis !important',
//             color: 'black'
//         }
//     }
// }));
export default function SearchSection() {
    const theme = useTheme();
    const navigate = useNavigate();
    const medicalRecordSelector = useSelector((state) => state.medicalRecord);

    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.record_type_name} ${option.record_name}`
    });

    return (
        <Autocomplete
            disablePortal
            fullWidth
            style={{ width: '25ch' }}
            id="combo-box-demo"
            disableClearable
            selectOnFocus
            // forcePopupIcon
            filterOptions={filterOptions}
            size={'small'}
            options={medicalRecordSelector.medicalRecord}
            getOptionLabel={(option) => option.record_name}
            onChange={(_event, newValue) => {
                navigate(`/app/edit/${newValue.slug}`);
            }}
            renderOption={(props, option) => <li {...props}>{option.record_name}</li>}
            renderInput={(params) => (
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{ color: 'white' }} />
                            </InputAdornment>
                        )
                    }}
                    {...params}
                    label="Quick search"
                />
            )}
        />
    );
}

//    InputProps=
//             {{
//                 endAdornment: (
//                     <InputAdornment position="end">
//                         <IconButton onClick={handleShowPassword} edge="end">
//                             {showPassword ? <Visibility /> : <VisibilityOff />}
//                         </IconButton>
//                     </InputAdornment>
//                 )
//             }}
//             <Search>
//                 <SearchIconWrapper>
//                     <SearchIcon style={{ color: 'white' }} />
//                 </SearchIconWrapper>
//                 <StyledInputBase placeholder="Search medical record" inputProps={{ 'aria-label': 'search' }} />
//             </Search>
//             {/* <Card
//                 style={{
//                     position: 'absolute',
//                     width: '100%',
//                     height: '150px',
//                     top: '58',
//                     background: theme.palette.primary.light,
//                     overflow: 'auto',
//                     padding: '0px 20px',
//                     boxShadow: '0px 12px 16px rgba(33, 150, 243, 0.1), 0px 4px 56px rgba(33, 150, 243, 0.1)'
//                 }}
//             >
//                 <ListItemText primary="Spam" />
//                 <ListItemText primary="Spam" />
//                 <ListItemText primary="Spam" />
//                 <ListItemText primary="Spam" />
//                 <ListItemText primary="Spam" />
//                 <ListItemText primary="Spam" /> <ListItemText primary="Spam" />
//                 <ListItemText primary="Spam" /> <ListItemText primary="Spam" />
//             </Card>
