import { Box, Typography } from '@mui/material';
import React from 'react';

function MedicalCollegeFooter() {
    const useContentTxtStyle = {
        '&.MuiTypography-root': {
            fontSize: 14,
            color: 'white',
            fontFamily: 'Gilroy-Regular'
        }
    };
    return (
        <Box
            sx={{
                '&.MuiBox-root': {
                    backgroundColor: '#151515',
                    padding: '0 4rem',
                    '@media (max-width:500px)': {
                        padding: '0 2rem'
                    }
                }
            }}
        >
            <Typography
                variant="h5"
                pt={2}
                pb={1}
                component="div"
                style={{ fontSize: 15, textAlignLast: 'center' }}
                sx={useContentTxtStyle}
            >
                &copy; {new Date().getFullYear()} powered by mdrxonline.com.
            </Typography>
        </Box>
    );
}

export default MedicalCollegeFooter;
