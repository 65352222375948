// assets
import ArticleIcon from '@mui/icons-material/Article';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import HomeIcon from '@mui/icons-material/Home';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PersonIcon from '@mui/icons-material/Person';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import KeyIcon from '@mui/icons-material/Key';
import HistoryIcon from '@mui/icons-material/History';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const isUserFreePlan = sessionStorage.getItem('userPlan')?.subscribed_to === 'Free' ? false : true;
const layout = 'app';

const mainMenu = {
    id: 'mainMenu',
    // title: 'Main Menu',
    type: 'group',
    children: [
        // dashboard
        {
            id: 'dashboardId',
            title: 'DashBoard',
            type: 'item',
            url: `${layout}/dashboard`,
            icon: HomeIcon,
            breadcrumbs: false
        },
        {
            id: 'medical-recordId',
            title: 'Medical Records',
            type: 'item',
            url: `${layout}/medical-record`,
            icon: ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'medical-history',
            title: 'Medical History',
            type: 'item',
            url: `${layout}/medical-history`,
            icon: HistoryIcon,
            breadcrumbs: false
        },
        {
            id: 'medical-services',
            title: 'Medical Services',
            type: 'item',
            url: `${layout}/services`,
            icon: MiscellaneousServicesIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'home12e',
        //     title: 'History',
        //     type: 'item',
        //     url: '#',
        //     icon: HistoryEduIcon,
        //     breadcrumbs: false
        // },

        {
            id: 'authenticationId',
            title: 'Settings',
            type: 'collapse',
            icon: SettingsApplicationsIcon,
            children: [
                {
                    id: 'myprofileId',
                    title: 'Profile',
                    type: 'item',
                    url: `${layout}/myprofile`,
                    icon: PersonIcon,
                    breadcrumbs: true,
                    isRouteEnabled: false
                },
                {
                    id: 'orderHistory',
                    title: 'Order History',
                    type: 'item',
                    url: `${layout}/order-history`,
                    icon: HistoryIcon,
                    breadcrumbs: false
                },
                {
                    id: 'changePinId',
                    title: 'Change PIN',
                    type: 'item',
                    url: `${layout}/change-pin`,
                    icon: KeyIcon,
                    breadcrumbs: false
                },
                {
                    id: 'binId',
                    title: 'Recycle Bin',
                    type: 'item',
                    url: `${layout}/bin`,
                    icon: ImageIcon,
                    breadcrumbs: false
                },
                {
                    id: 'faqId',
                    title: 'FAQ',
                    type: 'item',
                    url: `${layout}/faq`,
                    icon: MessageRoundedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'contact-usId',
                    title: 'Contact Us',
                    type: 'item',
                    url: `${layout}/contact-us`,
                    icon: GridViewRoundedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'billingId',
                    title: 'Billing',
                    type: 'item',
                    url: `${layout}/contact-us`,
                    icon: CurrencyLiraIcon,
                    breadcrumbs: false,
                    isRouteEnabled: isUserFreePlan
                }
            ]
        }
    ]
};

export default mainMenu;
