import * as constant from './medicalVitalTypes';

const initialState = {
    loading: false,
    medicalVital: [],
    error: ''
};

const medicalVitalReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.FETCH_MEDICAL_VITAL_REQUEST:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_MEDICAL_VITAL_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalVital: action.payload,
                error: ''
            };

        case constant.FETCH_MEDICAL_VITAL_FAILURE:
            return {
                ...state,
                loading: false,
                medicalVital: [],
                error: action.payload
            };
        case constant.ADD_MEDICAL_VITAL:
            return {
                ...state,
                loading: false,
                medicalVital: [...state.medicalVital, action.payload],
                error: ''
            };
        case constant.DELETE_MEDICAL_VITAL:
            const deletedData = state.medicalVital.filter((item) => item.id !== action.payload);
            return {
                ...state,
                loading: false,
                medicalVital: deletedData,
                error: ''
            };

        default:
            return state;
    }
};
export default medicalVitalReducer;
