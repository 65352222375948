// const URL = 'https://test.mdrxonline.com:8000/api/v2/';
const URL = 'https://mdrxonline.com:8000/api/v2/';

export const constants = {
    baseURL: 'https://mdrxonline.com:8000/api/v2/',
    // baseURL: 'https://test.mdrxonline.com:8000/api/v2/',
    updatedBaseURL: URL,
    login: URL + 'Account/Login',
    createUser: URL + 'auth/register/',
    verifyUser: URL + 'verify-email/',
    getAllCountries: URL + 'auth/list-country/',
    sendOtp: URL + 'auth/receive-otp/',
    sendPhoneOtp: URL + 'social-auth/phone/send-otp/',
    verifyPhoneOtp: URL + 'social-auth/phone/verify-otp/',
    verifyOtp: URL + 'auth/login/',
    gerUserAllRecord: URL + 'userrecord/list',
    deleteMedicalRecord: URL + 'userrecord/',
    deleteRecordFile: URL + 'userrecord/recordfile/delete/',
    changePin: URL + 'user/change-password/',
    forgotPassword: URL + 'auth/password/reset/',
    getUserPlanDetails: URL + 'user/data-used/',
    getShareUrlDetails: URL + 'get-share-record-info/',
    getUserProfile: URL + 'user/profile/',
    postContactUs: URL + 'contact-us/',
    postProfile: URL + 'user/profile/',
    gerUserLatestRecord: URL + 'userrecord/list/latest',
    getUserAllRecordFromInstantShare: URL + 'instant-share-records/',
    quickUploadSaveRecord: URL + 'instant-upload/save-record/',
    quickUploadFileNameUpload: URL + 'instant-upload/get-presigned-data/',
    quickUploadAWSFileUploadURL: 'https://keeprexweb.s3.amazonaws.com/',
    getArticles: URL + 'blogs/',
    getArticleVideos: URL + 'blogs/video/',
    gerAllRecordTypeList: URL + 'userrecord/listrecordtype/',

    resetPin: URL + 'auth/password/reset/confirm/',

    // rename
    getFileNameRename: URL + 'userrecord/recordfile/rename/',

    // Stripe Billing
    postCheckOut: URL + 'subscription/checkout/',
    getBilling: URL + 'subscription/customer-portal/',

    // Medical History

    medicalHistory: {
        condition: {
            getAll: URL + 'medical-data/condition/',
            post: URL + 'medical-data/condition/'
        },
        allergies: {
            getAll: URL + 'medical-data/allergy/',
            post: URL + 'medical-data/allergy/'
        },
        vitals: {
            getAll: URL + 'medical-data/vitals/',
            post: URL + 'medical-data/vitals/',
            delete: URL + 'medical-data/vitals/delete/'
        }
    },

    // InstantShare
    getInstantShareCode: URL + 'get-instant-share-code/',

    // Speedo Service
    speedo: {
        post: URL + 'partner/products/checkout/',
        getServiceList: URL + 'partner/partners/'
    },

    visitor: {
        post: URL + 'partner/partner-visitor/'
    }
};

export const definedRoles = {
    user_role: ['Admin', 'User']
};

export const sekretKey = {
    googleCaptcha: '6LcUpD4cAAAAACs--jpVPLAdqcRMgDq67EbYaTTG'
};

export const googleAnalytics = {
    trackingId: 'UA-234762234-2'
};

export const socialLogin = {
    getGoogleId: '281895212662-3k78pmvsd3sf3qj0qaok182u0u3t6859.apps.googleusercontent.com'
};

export const doctorAccess = {
    URL: 'https://doc.mdrxonline.com'
    // URL: 'https://test.doc.mdrxonline.com'
};

export const projectVersion = {
    version: 'v2.18.00'
};

export const ipDetailsURL = 'https://ipapi.co/json/';
