import { contact_us_1 } from 'Constants/Images';

const colors = {
    white: '#fff',
    themeFont1: '#1D2130BF',
    themeFont2: '#031432',
    themeFont3: '#151515',
    themeFont4: '#1D2130',
    themeFont5: '#7D7987CC',
    themeFont6: '#2A3342',
    themeFont7: '#83858D',
    themeColor1: '#0EA1D7',
    themeColor2: '#0EA1D71A',
    themeColor3: '#2196F3'
};

const boxShadow = '0.7px 0.7px 5.3px rgba(0, 0, 0, 0.04),2.2px 2.2px 17.9px rgba(0, 0, 0, 0.06),10px 10px 80px rgba(0, 0, 0, 0.1)';
const imageRender = '-webkit-optimize-contrast';
const useMainContStyles = {
    '&.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        height: '100vh'
    }
};
const useHeaderContStyles = {
    '&.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    }
};
const useHeaderTxtStyle = {
    '&.MuiTypography-root': {
        fontFamily: 'Gilroy-Bold',
        fontSize: '2.5rem',
        fontWeight: 600,
        color: colors.white,
        position: 'absolute',
        width: '60%',
        height: '99%',
        paddingLeft: '4rem',
        paddingRight: '12rem',
        paddingTop: '14rem',
        top: 0,
        transition: '3s ease-in',
        // background:
        //     'linear-gradient(90deg, rgba(0, 0, 0, 0.85) -20.45%, rgba(0, 0, 0, 0.85) -8.86%, rgba(0, 0, 0, 0.75) 16.88%, rgba(0, 0, 0, 0) 105.69%)',
        '@media (max-width: 600px)': {
            paddingRight: '5rem',
            paddingLeft: '3.5rem',
            fontSize: '1.5rem',
            height: '50vh'
        },
        '@media (max-width: 550px)': {
            paddingRight: '5rem',
            paddingLeft: '2.5rem',
            width: '100%',
            fontSize: '1.2rem',
            height: '50vh'
        }
    }
};
const img_style_header = {
    width: '100%',
    // maxHeight: '33rem',
    height: '96vh',
    objectFit: 'cover',
    imageRendering: imageRender,
    '@media (max-width: 600px)': {
        height: '50vh'
    },
    '@media (max-width: 550px)': {
        height: '50vh '
    }
};
const useButtonStyles = {
    '&.MuiButton-root': {
        backgroundColor: colors.white,
        width: '10rem',
        height: '3rem',
        borderRadius: '10px',
        position: 'absolute',
        bottom: '6rem',
        left: '4rem',
        width: '9.5rem',
        padding: '5px',
        boxShadow: boxShadow,
        '@media (max-width: 600px)': {
            left: '3.5rem'
        },
        '@media (max-width: 550px)': {
            left: '2.5rem'
        }
    },
    '&.MuiButton-text': {
        color: colors.themeFont4,
        fontSize: '14px',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontFamily: 'Gilroy-Medium'
    }
};
const useAboutUsTxtStyle = {
    '&.MuiTypography-root': {
        fontFamily: 'Gilroy-Bold',
        fontSize: '1.6rem',
        fontWeight: 900,
        color: colors.themeFont2
    }
};
const useAboutUsParaTxtStyle = {
    '&.MuiTypography-root': {
        fontFamily: 'Gilroy-Regular',
        fontSize: '20px',
        fontWeight: 400,
        color: colors.themeFont1,
        lineHeight: '1.3rem'
    }
};
const img_about_us_style = {
    maxWidth: '100%',
    width: '100%',
    // maxHeight: '22rem',
    minWidth: '100%',
    // minHeight: '10rem',
    objectFit: 'contain',
    boxShadow: boxShadow,
    borderRadius: '20px',
    imageRendering: imageRender
};
const useButtonThemeColoredStyles = {
    '&.MuiButton-root': {
        backgroundColor: colors.themeColor3,
        width: '7rem',
        height: '2.3rem',
        borderRadius: '12px',
        padding: '5px',
        boxShadow: boxShadow
    },
    '&.MuiButton-text': {
        color: colors.white,
        fontSize: '13px',
        textTransform: 'capitalize',
        fontWeight: 500,
        fontFamily: 'Gilroy-Medium'
    }
};

const useButtonOutlinedThemeColoredStyles = {
    '&.MuiButton-root': {
        backgroundColor: colors.white,
        width: '7rem',
        height: '2.3rem',
        borderRadius: '12px',
        border: `2px solid ${colors.themeColor3}`,
        padding: '5px',
        boxShadow: boxShadow
    },
    '&.MuiButton-text': {
        color: colors.themeColor3,
        fontSize: '13px',
        textTransform: 'capitalize',
        fontWeight: 800,
        fontFamily: 'Gilroy-Medium'
    }
};
const useFeaturedContStyles = {
    '&.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.themeColor2,
        padding: '3rem 4rem',
        '@media (max-width:500px)': {
            padding: '3rem 2rem'
        }
    }
};

const useServicesContStyles = {
    '&.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        padding: '3rem 4rem',
        '@media (max-width:500px)': {
            padding: '3rem 2rem'
        }
    }
};

const useFeaturedItemsStyles = {
    '&.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        padding: '1rem',
        borderRadius: '1rem',
        boxShadow: boxShadow
    }
};
const useButtonTransparentStyles = {
    '&.MuiButton-root': {
        backgroundColor: colors.white,
        width: '8rem',
        display: 'flex',
        borderRadius: 20,
        alignItems: 'center'
    },
    '&.MuiButton-text': {
        color: colors.themeColor1,
        fontSize: '13px',
        textTransform: 'capitalize',
        fontWeight: 500,
        fontFamily: 'Gilroy-Bold'
    }
};
const useAppDownloadContStyle = {
    '&.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: colors.themeColor2,
        backgroundImage: `url(${contact_us_1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        height: '70vh',
        // maxHeight: '45rem',
        // width: '100%',
        padding: '4rem',
        '@media (max-width:500px)': {
            padding: '3rem 2rem'
        }
    }
};
const useAppDownloadTxtStyle = {
    '&.MuiTypography-root': {
        fontSize: 14,
        color: colors.themeFont5,
        fontFamily: 'Gilroy-Bold',
        lineHeight: '1.3rem'
    }
};
const useAppDownloadTxtContStyle = {
    '&.MuiBox-root': {
        height: '45rem',
        maxHeight: '45rem',

        '@media (max-width:1700px)': {
            height: '50rem',
            maxHeight: '50rem',
            width: '40%'
        },
        '@media (max-width:1400px)': {
            height: '46rem',
            maxHeight: '46rem',
            width: '60%'
        },
        '@media (max-width:1200px)': {
            height: '38rem',
            maxHeight: '38rem',
            width: '60%'
        },
        '@media (max-width:1100px)': {
            height: '35rem',
            maxHeight: '35rem',
            width: '60%'
        },
        '@media (max-width:900px)': {
            height: '26rem',
            maxHeight: '26rem',
            width: '60%'
        },
        '@media (max-width:800px)': {
            height: '23rem',
            maxHeight: '23rem',
            width: '60%'
        },
        width: '50%',
        '@media (max-width:700px)': {
            height: '21rem',
            maxHeight: '21rem',
            width: '60%'
        },
        '@media (max-width:600px)': {
            height: '18rem',
            maxHeight: '18rem',
            width: '70%'
        },
        '@media (max-width:500px)': {
            width: '90%',
            height: '15rem',
            maxHeight: '15rem'
        }
    }
};
const useContactHeadingTxt = {
    '&.MuiTypography-root': {
        fontSize: 16,
        color: colors.themeFont6,
        fontFamily: 'Gilroy-Bold',
        margin: '5px 0'
    }
};
const useContactDetailTxt = {
    '&.MuiTypography-root': {
        fontSize: 11,
        color: colors.themeFont1,
        fontFamily: 'Gilroy-Regular'
    }
};
const useLogoTxtStyle = {
    '&.MuiTypography-root': {
        fontSize: 16,
        color: colors.white,
        fontFamily: 'Gilroy-Bold',
        fontWeight: 'bold'
    }
};
const useContentTxtStyle = {
    '&.MuiTypography-root': {
        fontSize: 14,
        color: colors.white,
        fontFamily: 'Gilroy-Regular'
    }
};
const useBoxCommonContStyle = {
    '&.MuiBox-root': {
        margin: '3rem 4rem',
        '@media (max-width:500px)': {
            margin: '3rem 2rem'
        }
    }
};
const imgShade = {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
    height: '98.5%',
    width: '100%',
    borderRadius: 20
};
const useHowItWorksContStyles = {
    '&.MuiBox-root': {
        backgroundColor: colors.themeColor2,
        padding: '3rem 0 3rem 4rem',
        '@media (max-width:500px)': {
            padding: '3rem 0 3rem 2rem'
        }
    }
};

export {
    colors,
    boxShadow,
    imageRender,
    useMainContStyles,
    useHeaderContStyles,
    useHeaderTxtStyle,
    img_style_header,
    useButtonStyles,
    useAboutUsTxtStyle,
    useAboutUsParaTxtStyle,
    img_about_us_style,
    useButtonThemeColoredStyles,
    useFeaturedContStyles,
    useFeaturedItemsStyles,
    useButtonTransparentStyles,
    useAppDownloadContStyle,
    useAppDownloadTxtContStyle,
    useContactHeadingTxt,
    useContactDetailTxt,
    useLogoTxtStyle,
    useContentTxtStyle,
    useBoxCommonContStyle,
    imgShade,
    useAppDownloadTxtStyle,
    useHowItWorksContStyles,
    useServicesContStyles,
    useButtonOutlinedThemeColoredStyles
};
