/* eslint-disable react/prop-types */
import { Button, Card, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import AddMedicalRecord from 'views/AddRecord/AddMedicalRecord';
import { store } from 'store';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Router, Routes, useNavigate } from 'react-router-dom';

export default function CommonTopHeader(props) {
    const { headerName, headerImage, isSecondHeader, isAddRecord, isNavigate, handleNavigate } = props;

    const handleAddRecord = () => {
        ReactDOM.render(
            <Provider store={store}>
                <AddMedicalRecord />
            </Provider>,
            document.getElementById('add-record')
        );
    };
    return (
        <Card className="record-header" style={{ maxHeight: isAddRecord && 76, minHeight: isAddRecord && 76 }}>
            <Box style={{ padding: '10px', display: 'flex', background: '#d3eafd', justifyContent: 'space-between' }}>
                <Box>
                    <Typography
                        // fontSize={24}
                        sx={{ fontSize: { md: '24px', lg: '24px', sm: '16px' } }}
                        fontWeight={400}
                        style={{ color: 'black', fontFamily: 'Gilroy-Bold' }}
                    >
                        {isNavigate && (
                            <IconButton aria-label="Back" onClick={() => handleNavigate()}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        )}
                        {headerName}
                    </Typography>
                    {isSecondHeader && (
                        <>
                            <Typography
                                mt={1}
                                sx={{ fontSize: { md: '16px', lg: '16px', sm: '10px' } }}
                                style={{ color: '#636A70', fontFamily: 'Gilroy-Medium' }}
                            >
                                Hospitals are mandated to keep your records for only ten years.
                            </Typography>
                            <Button
                                disableElevation
                                // disabled={isSubmitting}
                                onClick={() => handleAddRecord()}
                                startIcon={<AddIcon />}
                                style={{ marginTop: 10 }}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Add Records
                            </Button>
                        </>
                    )}
                </Box>
                <Box>
                    <img src={headerImage} style={{ width: isAddRecord && 60 }} className="edit-view" alt="no" />
                </Box>
            </Box>
        </Card>
    );
}

CommonTopHeader.propTypes = {
    headerName: PropTypes.string.isRequired,
    headerImage: PropTypes.string.isRequired,
    isNavigate: PropTypes.bool,
    isSecondHeader: PropTypes.bool
};
