import { useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Navigate, useLocation } from 'react-router-dom';
import LocalStorage from 'utils/LocalStorage';
import { getRoleRoute } from 'views/utilities/utils';

// eslint-disable-next-line react/prop-types
const PrivateRoutes = ({ allowedRoles, component }) => {
    const location = useLocation();
    const { userDetails } = useSelector((state) => state.userSession);
    const isLoggedIn = LocalStorage.getUserToken() ? true : false;

    const auth = {
        roles: [userDetails?.role_id],
        user: isLoggedIn
    };

    return auth.user ? (
        // eslint-disable-next-line react/prop-types
        auth.roles?.find((role) => allowedRoles.includes(role)) ? (
            component
        ) : (
            <Navigate to={getRoleRoute()} state={{ from: location }} replace />
        )
    ) : (
        <Navigate to="login" state={{ from: location }} replace />
    );
};

export default PrivateRoutes;
