import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

function CustomCookie() {
    return (
        <div>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                declineButtonText="Cancel"
                cookieName="googleAnalytics"
                style={{ background: 'rgba(211, 234, 253, 1)', color: 'rgba(29, 33, 48, 1)' }}
                buttonStyle={{ background: 'rgba(33, 150, 243, 1)', color: 'white', fontSize: '13px' }}
                expires={150}
            >
                This site use 🍪, by clicking "Accept Cookies" you agree Google Analytics can store cookies who may combine it with other
                information that you’ve provided to them or that they’ve collected from your use of their services.
            </CookieConsent>
        </div>
    );
}

export default CustomCookie;
