import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// cookie
import CustomCookie from 'components/customCookie/CustomCookie';
import { useEffect } from 'react';
import LocalStorage from 'utils/LocalStorage';
import refreshToken, { isTokenAboutToExpire } from 'utils/axiosService';

const App = () => {
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        const accessToken = LocalStorage.getUserToken(); // Replace with the actual key you use for storing the access token
        const tokenExpiration = LocalStorage.getUserDetails();
        if (accessToken && tokenExpiration) {
            isTokenAboutToExpire() && refreshToken();
        }
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <CustomCookie />
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
