import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import LocalStorage from 'utils/LocalStorage';

export default function Logout() {
    const navigate = useNavigate();
    const handleLogout = async () => {
        LocalStorage.removeUser();
        navigate('/login');
        window.location.reload();
    };
    return (
        <ListItemButton
            onClick={() => handleLogout()}
            sx={{
                borderRadius: `10px`,
                mb: 0.5,
                alignItems: 'flex-start',
                background: 'white'
            }}
        >
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={'h5'} color="inherit">
                        {'Log Out'}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}
