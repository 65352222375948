// eslint-disable-next-line no-unused-vars
import { Navigate, useLocation } from 'react-router-dom';
import LocalStorage from 'utils/LocalStorage';
import { getRoleRoute } from 'views/utilities/utils';

// eslint-disable-next-line react/prop-types
const PublicRoutes = ({ component }) => {
    const location = useLocation();
    const isLoggedIn = LocalStorage.getUserToken() ? true : false;
    const auth = {
        user: isLoggedIn
    };

    return !auth.user ? component : <Navigate to={getRoleRoute()} state={{ from: location }} replace />;
};

export default PublicRoutes;
