import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
// material-ui
import { Avatar, Box, ButtonBase, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MenuIcon from '@mui/icons-material/Menu';
import SearchSection from './SearchSection/SearchSection';
import { useNavigate } from 'react-router-dom';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

// eslint-disable-next-line react/prop-types
const Header = ({ handleLeftDrawerToggle, drawerOpen, shortWindow }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const getName = useSelector((state) => state.userSession);
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isShareView = JSON.parse(sessionStorage.getItem('shareItem')) === null ? false : JSON.parse(sessionStorage.getItem('shareItem'));

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: matchUpMd ? (drawerOpen && shortWindow ? 80 : 228) : 228,
                    // height: '20px',
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {/* <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box> */}
                {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <MenuIcon stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase> */}
            </Box>
            <ListItemIcon sx={{ my: 'auto', minWidth: drawerOpen ? 0 : 36, ml: 2 }}>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.colors?.primaryLight,
                            // color: '',
                            '&:hover': {
                                background: theme.colors?.primaryMain,
                                color: theme.colors?.primaryLight
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <MenuIcon stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </ListItemIcon>
            {/* <ListItemIcon sx={{ my: 'auto', minWidth: 36, ml: 2 }}>
                <PersonRoundedIcon />
            </ListItemIcon> */}

            <ListItemText
                onClick={() => navigate('/app/myprofile')}
                sx={{ my: 'auto', minWidth: drawerOpen ? 0 : 36, ml: 2, cursor: 'pointer' }}
                primary={
                    isShareView ? (
                        <Typography variant={isMobile ? 'h4' : 'h2'}>
                            You are viewing <strong style={{ color: 'rgba(33, 150, 243, 1)' }}>{getName.userDetails.shared_by_name}</strong>{' '}
                            medical records
                        </Typography>
                    ) : (
                        <Typography variant={isMobile ? 'h4' : 'h2'}>{`${
                            getName?.userDetails?.first_name === undefined ? '' : getName?.userDetails?.first_name
                        }  ${getName?.userDetails?.last_name === undefined ? '' : getName?.userDetails?.last_name}`}</Typography>
                    )
                }
            />

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            <SearchSection />
            {/* <ProfileSection /> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
