import { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Container,
    Toolbar,
    Typography,
    Menu,
    MenuItem,
    AppBar,
    useMediaQuery,
    IconButton,
    Drawer,
    ListItemText,
    ListItemButton,
    ListItem
} from '@mui/material';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// project imports
import { Box, textAlign } from '@mui/system';
import { collegelogo, logo_blue } from 'Constants/Images';
import MenuIcon from '@mui/icons-material/Menu';
import MenuList from 'layout/AuthorisedLayout/Sidebar/MenuList';
import { drawerWidth } from 'store/constant';
import LogoSection from 'layout/AuthorisedLayout/LogoSection';
import { BrowserView } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { doctorAccess } from 'Constants/constants';
import { appConfiguration } from 'Constants/appConfiguration';

// ==============================|| MINIMAL LAYOUT ||============================== //

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const PublicLayout = () => {
    const theme1 = useTheme();
    const [appBarPosition, setPosition] = useState('static');
    const isMobile = useMediaQuery(theme1.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    let services_section = null;
    setTimeout(() => {
        services_section = document.querySelector('#services_section');
        document.addEventListener('scroll', (e) => {
            if (window.scrollY <= 4) {
                setPosition('static');
            }
            if (window.scrollY > 4) {
                setPosition('fixed');
            }
        });
    }, 10);

    const useMainContainerStyles = {
        '&.MuiBox-root': {
            display: 'flex',
            flexDirection: 'column'
        }
    };
    const useTopOptMenuContainerStyles = {
        '&.MuiBox-root': {
            backgroundColor: '#031432',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            paddingRight: '15px'
        }
    };
    const useTopOptMenuStyles = {
        '&.MuiBox-root': {
            margin: '0 18px',
            top: '-2px',
            position: 'relative'
        }
    };
    const useTopMenuLabelStyle = {
        '&.MuiTypography-root': {
            fontFamily: 'Gilroy-Medium',
            fontSize: '13px',
            color: '#fff',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        }
    };
    const useOptMenuItemContainer = {
        '&.MuiPaper-root .MuiList-root-MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            padding: '10px'
        }
    };
    const useButtonStyles = {
        '&.MuiButton-root': {
            backgroundColor: '#2196F3',
            width: '7rem',
            height: '2.4rem',
            borderRadius: '10px',

            '&.MuiButton-root:hover': {
                opacity: 1,
                backgroundColor: '#2196F3'
            }
        },
        '&.MuiButton-text': {
            color: '#fff',
            fontSize: '14px',
            textTransform: 'capitalize',
            fontWeight: '400',
            fontFamily: 'Gilroy-Medium'
        }
    };
    const useToolbarStyles = {
        '&.MuiToolbar-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '4.5rem',
            maxHeight: '4.5rem'
        }
    };
    const useNavLinkStyle = {
        '&.MuiTypography-root': {
            fontFamily: 'Gilroy-Medium',
            cursor: 'pointer',
            margin: '0 10px',
            display: 'flex',
            alignItems: 'center'
        },
        '&.MuiTypography-root:active': {
            fontWeight: 'bold',
            color: '#101010'
        },
        '&.MuiTypography-root:visited': {
            fontWeight: 'bold',
            color: '#101010'
        }
    };
    const useNavLinkLogoTxtStyle = {
        '&.MuiTypography-root': {
            fontFamily: 'Gilroy-Bold',
            cursor: 'pointer',
            color: '#1D2130',
            fontSize: '25px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center'
        }
    };
    const [showTopMenu, setShowTopMenu] = useState(null);
    const open = Boolean(showTopMenu);
    const [showResourceMenu, setShowResourceMenu] = useState(null);
    const openResourceMenu = Boolean(showResourceMenu);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const isShareView = JSON.parse(sessionStorage.getItem('shareItem')) === null ? false : JSON.parse(sessionStorage.getItem('shareItem'));

    const handleLogoutSession = () => {
        sessionStorage.clear();
        navigate('/');
    };

    const nagivateAdminAccess = () => {
        window.open(doctorAccess.URL, '_blank');
    };

    const pages = [
        { id: 1, label: 'Home', path: '/home', optMenu: [] },
        { id: 2, label: 'How it Works', path: '/how-it-works', optMenu: [] },
        { id: 3, label: 'Our Solution', path: '/our-solution', optMenu: [] },
        {
            id: 4,
            label: 'Resources',
            path: '/articles',
            optMenu: [
                // { id: 1, optLabel: 'Blog', path: '/blog' },
                // { id: 2, optLabel: 'Article', path: '/articles' }
            ]
        },
        // { id: 5, label: 'Blog', path: '/blog', optMenu: [] },

        { id: 6, label: 'Privacy', path: '/privacy', optMenu: [] },
        { id: 7, label: 'Services', path: '/services', optMenu: [] }
    ];

    const TOP_OPTION_MENU = [
        {
            id: 1,
            label: 'Language',
            optMenu: [
                { id: 1, optLabel: 'English', path: null },
                { id: 2, optLabel: 'French', path: '/french' }
            ],
            path: location.pathname
        }
        // { id: 2, label: 'Instant Share Access', optMenu: [], path: '/doctor-access' }
    ];

    const TOP_OPTION_MENU_HARD_LINK = [{ id: 4, label: 'Doctors Access', optMenu: [], path: doctorAccess.URL }];

    const handleTopMenu = (event) => {
        setShowTopMenu(event.currentTarget);
    };

    const handleTopMenuClose = () => {
        setShowTopMenu(null);
    };

    const handleResourceMenu = (event) => {
        setShowResourceMenu(event.currentTarget);
    };

    const handleResourceMenuClose = () => {
        setShowResourceMenu(null);
    };

    const renderTopOptMenuItems = (opt) => {
        if (opt.optMenu.length > 0) {
            return (
                <Menu
                    id="basic-menu"
                    anchorEl={showTopMenu}
                    open={open}
                    onClose={handleTopMenuClose}
                    sx={useOptMenuItemContainer}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    {opt.optMenu.map((optItem) => (
                        <NavLink
                            href="#x"
                            style={{ textDecoration: 'none' }}
                            key={optItem.toString()}
                            to={optItem.path ? optItem.path : location.pathname}
                        >
                            <MenuItem key={optItem.id} onClick={handleTopMenuClose}>
                                {optItem.optLabel}
                            </MenuItem>
                        </NavLink>
                    ))}
                </Menu>
            );
        }
    };

    const renderResourceMenuItems = (opt) => {
        if (opt.optMenu.length > 0) {
            return (
                <Menu
                    id="basic-menu-resource"
                    anchorEl={showResourceMenu}
                    open={openResourceMenu}
                    onClose={handleResourceMenuClose}
                    sx={useOptMenuItemContainer}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    {opt.optMenu.map((optItem) => (
                        <NavLink
                            href="#x"
                            style={{ textDecoration: 'none' }}
                            key={optItem.id}
                            to={optItem.path ? optItem.path : location.pathname}
                        >
                            <MenuItem onClick={handleResourceMenuClose}>{optItem.optLabel}</MenuItem>
                        </NavLink>
                    ))}
                </Menu>
            );
        }
    };

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    {/* {drawerOpen && !shortWindow && matchUpMd ? <MenuList /> : <SideBarIconList />} */}

                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
        </>
    );

    return (
        <Box sx={useMainContainerStyles}>
            {appConfiguration.showTopHeader && (
                <Box sx={useTopOptMenuContainerStyles}>
                    {TOP_OPTION_MENU.map((optMenuItem) => (
                        <Box sx={useTopOptMenuStyles} key={optMenuItem.id}>
                            <NavLink
                                href="#x"
                                style={{ textDecoration: 'none', margin: '0 .6rem' }}
                                key={optMenuItem.id}
                                to={optMenuItem.path}
                            >
                                <Typography
                                    variant="h6"
                                    component="div"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    sx={useTopMenuLabelStyle}
                                    onClick={(e) => optMenuItem.optMenu.length > 0 && handleTopMenu(e)}
                                >
                                    {optMenuItem.label}
                                    {optMenuItem.optMenu.length > 0 && (
                                        <KeyboardArrowDownIcon style={{ position: 'relative', top: 1 }} color="#fff" height={8} />
                                    )}
                                </Typography>
                            </NavLink>
                            {renderTopOptMenuItems(optMenuItem)}
                        </Box>
                    ))}
                    {TOP_OPTION_MENU_HARD_LINK.length > 0 &&
                        TOP_OPTION_MENU_HARD_LINK.map((optMenuItem) => (
                            <Box sx={useTopOptMenuStyles} key={optMenuItem.id}>
                                <a
                                    href={optMenuItem.path}
                                    target={'_blank'}
                                    style={{ textDecoration: 'none', margin: '0 .6rem' }}
                                    key={optMenuItem.id}
                                >
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        sx={useTopMenuLabelStyle}
                                        onClick={(e) => optMenuItem.optMenu.length > 0 && handleTopMenu(e)}
                                    >
                                        {optMenuItem.label}
                                    </Typography>
                                </a>
                                {/* {renderTopOptMenuItems(optMenuItem)} */}
                            </Box>
                        ))}
                </Box>
            )}

            <AppBar style={{ background: 'white' }} position={appBarPosition} elevation={3}>
                <div className={location.pathname === '/blog' ? 'container' : 'fluid'}>
                    <Toolbar sx={useToolbarStyles}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            {isMobile && !appConfiguration.haveGadMedical && (
                                <>
                                    <IconButton
                                        size="large"
                                        onClick={() => setDrawerOpen(!drawerOpen)}
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon color="primary" stroke={1.5} size="1.3rem" />
                                    </IconButton>
                                    {drawerOpen && (
                                        <Drawer
                                            anchor={'left'}
                                            open={drawerOpen}
                                            // sx={{ minWidth: '300px' }}
                                            onClose={() => setDrawerOpen(!drawerOpen)}
                                            sx={{
                                                '& .MuiDrawer-paper': {
                                                    width: drawerWidth,
                                                    background: theme.palette.primary.light,
                                                    color: theme.palette.text.primary,
                                                    borderRight: 'none'

                                                    // [theme.breakpoints.up('md')]: {
                                                    //     top: '88px'
                                                    // }
                                                }
                                            }}
                                            ModalProps={{ keepMounted: true }}
                                        >
                                            <Box
                                                sx={{
                                                    display: { xs: 'flex', md: 'none' },
                                                    flexDirection: { xs: 'column' },
                                                    width: '250px'
                                                }}
                                            >
                                                {drawer}
                                                <MenuList />
                                            </Box>
                                        </Drawer>
                                    )}
                                </>
                            )}
                            <NavLink style={{ textDecoration: 'none' }} to={'/home'}>
                                <Typography variant="h2" component="div" sx={useNavLinkLogoTxtStyle} onClick={() => navigate('/home')}>
                                    <img
                                        style={{ height: 35, width: 35, marginRight: 15, position: 'relative', top: -3 }}
                                        src={appConfiguration.haveGadMedical ? collegelogo : logo_blue}
                                        alt="MdRx-logo"
                                    />
                                    <span>{appConfiguration.haveGadMedical ? 'Guru Angad Dev Medical Centre Portal' : 'MdRx'}</span>
                                </Typography>
                            </NavLink>
                        </Box>

                        {!isShareView && (
                            <>
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    {appConfiguration.showHeaderMenu &&
                                        pages.map((page) => (
                                            <Box
                                                onClick={() => {
                                                    // if (page.path) {
                                                    navigate(page.path);
                                                    // }
                                                    // if (services_section) {
                                                    //     services_section.scrollIntoView();
                                                    // }
                                                }}
                                                key={page.id}
                                                style={{ margin: '0 .5rem' }}
                                            >
                                                {/* <NavLink href="#x" style={{ textDecoration: 'none', margin: '0 .6rem' }} to={page.path}> */}
                                                <Typography
                                                    variant="h5"
                                                    color={'#1D2130BF'}
                                                    component="a"
                                                    sx={useNavLinkStyle}
                                                    style={{
                                                        position: 'relative',
                                                        top: page.optMenu.length > 0 ? -3 : 0,
                                                        left: page.optMenu.length > 0 ? 5 : 0
                                                    }}
                                                    aria-controls={openResourceMenu ? 'basic-menu-resource' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openResourceMenu ? 'true' : undefined}
                                                    onClick={(e) => page.optMenu.length > 0 && handleResourceMenu(e)}
                                                >
                                                    {page.label}
                                                    {page.optMenu.length > 0 && (
                                                        <KeyboardArrowDownIcon
                                                            style={{ position: 'relative', top: 1 }}
                                                            color="#fff"
                                                            height={8}
                                                        />
                                                    )}
                                                </Typography>
                                                {/* </NavLink> */}
                                                {renderResourceMenuItems(page)}
                                            </Box>
                                        ))}
                                </Box>
                                {appConfiguration.haveGadMedical ? (
                                    <>
                                        <Button onClick={() => nagivateAdminAccess()} sx={useButtonStyles} color="inherit">
                                            Admin Access
                                        </Button>
                                    </>
                                ) : (
                                    <Button onClick={() => navigate('/login')} sx={useButtonStyles} color="inherit">
                                        Login
                                    </Button>
                                )}
                            </>
                        )}
                        {isShareView && (
                            <Button color="inherit" onClick={() => handleLogoutSession()}>
                                Log Out
                            </Button>
                        )}
                    </Toolbar>
                </div>
            </AppBar>
            <Main theme={theme} style={{ borderRadius: 0, marginTop: '0px', padding: '0px', minHeight: '100vh' }}>
                {/* breadcrumb */}
                {/* <Container sx={{ '&.MuiContainer-root': { paddingLeft: 0, paddingRight: 0 } }} maxWidth="lg"> */}
                <Outlet />

                {/* </Container> */}
            </Main>
        </Box>
    );
};

export default PublicLayout;
