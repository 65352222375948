const layout = 'app';
import ShareIcon from '@mui/icons-material/Share';

const shareMenu = {
    id: 'shareMenu',
    // title: 'Main Menu',
    type: 'group',
    children: [
        // dashboard
        {
            id: 'shareMenushareId',
            title: 'Medical Record',
            type: 'item',
            url: `share/view-shared-record-list`,
            icon: ShareIcon,
            breadcrumbs: false
        }
    ]
};

export default shareMenu;
