import * as constant from './LoginUserTypes';

export const setUserInformationSuccess = (user) => {
    return {
        type: constant.SET_USER_LOGIN_INFORMATION,
        payload: user
    };
};
export const updateUserNameInformation = (user) => {
    return {
        type: constant.UPDATE_USER_INFORMATION,
        payload: user
    };
};

// Set User Information
export const setUserInformation = (user) => (dispatch) => {
    return dispatch(setUserInformationSuccess(user));
};

export const updateUserInformation = (user) => (dispatch) => {
    return dispatch(updateUserNameInformation(user));
};
