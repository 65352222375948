/* eslint-disable react/prop-types */
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { HashRouter } from 'react-router-dom';
// import Theme from '../components/theme';
import { Slide, Grid, Dialog, Typography } from '@mui/material';
import { closeDialog } from 'views/utilities/utils';
import { medicalModalIcon } from 'Constants/Images';
import MedicalHistoryCommonCard from 'views/MedicalHistory/MedicalHistoryCommonCard';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FormDialog = (props) => {
    const doAction = () => {
        closeDialog();
        props.action();
    };

    let { title, Component, maxSize, action, fullScreen } = props;
    return (
        <HashRouter>
            <Dialog
                TransitionComponent={Transition}
                keepMounted
                fullScreen={fullScreen}
                fullWidth
                maxWidth={maxSize}
                open={true}
                onClose={() => (action ? doAction() : closeDialog())}
            >
                {title !== null && title && (
                    <React.Fragment>
                        {props.isMedicalHitory ? (
                            <MedicalHistoryCommonCard headerName={title} headerImage={medicalModalIcon} />
                        ) : (
                            <DialogTitle>
                                <Grid container>
                                    <Grid container item lg={11} xs={11}>
                                        <Typography variant="subtitle1">{title}</Typography>
                                    </Grid>
                                    <Grid container item lg={1} xs={1} justifyContent="flex-end">
                                        <IconButton size="small" aria-label="close" onClick={() => closeDialog()}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                        )}
                    </React.Fragment>
                )}
                <DialogContent dividers style={{ padding: 10 }}>
                    {/* Used for Composite Components (Those components which is sended as Prop) to achieve Props. */}
                    {React.cloneElement(Component, { propName: 'propValue' })}
                </DialogContent>
            </Dialog>
        </HashRouter>
    );
};

FormDialog.propTypes = {
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    maxSize: PropTypes.string,
    isMedicalHitory: PropTypes.bool
};

export default FormDialog;
