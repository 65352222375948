/* eslint-disable react/prop-types */
import { Button, Card, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';
import { closeDialog } from 'views/utilities/utils';
import CloseIcon from '@mui/icons-material/Close';

export default function MedicalHistoryCommonCard(props) {
    const { headerName, headerImage } = props;

    return (
        <Card variant="outlined" className="record-header" style={{ maxHeight: 100, minHeight: 100 }}>
            <Box style={{ padding: '10px', display: 'flex', background: '#d3eafd', justifyContent: 'space-between' }}>
                <Box>
                    <Typography
                        sx={{ fontSize: { md: '24px', lg: '24px', sm: '16px' } }}
                        fontWeight={400}
                        style={{ color: 'black', fontFamily: 'Gilroy-Bold' }}
                    >
                        {headerName}
                    </Typography>
                </Box>
                <Box>
                    <img src={headerImage} style={{ width: 90, marginRight: 20 }} className="edit-view" alt="no" />
                    <IconButton
                        size="small"
                        aria-label="close"
                        style={{ position: 'absolute', right: 0, color: 'black' }}
                        onClick={() => closeDialog()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
}

MedicalHistoryCommonCard.propTypes = {
    headerName: PropTypes.string.isRequired,
    headerImage: PropTypes.string.isRequired
};
