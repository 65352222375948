import config from 'config';

const ContactUs = Loadable(lazy(() => import('views/ContactUs/ContactUs')));
const DiagnosingTimeline = Loadable(lazy(() => import('views/DiagnosingTimeline/DiagnosingTimeline')));
const EditMedicalRecord = Loadable(lazy(() => import('views/EditMedicalRecord/EditMedicalRecord')));

const Faq = Loadable(lazy(() => import('views/Faq/Faq')));
const GdprPolicy = Loadable(lazy(() => import('views/Footer/FooterPages/GdprPolicy')));
const PrivacyPolicy = Loadable(lazy(() => import('views/Footer/FooterPages/PrivacyPolicy')));

import { Navigate } from 'react-router-dom';
import AuthorisedLayout from 'layout/AuthorisedLayout';
import PublicLayout from 'layout/PublicLayout';
import PrivateRoutes from './privateRoutes';
import PublicRoutes from './publicRoutes';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import AddMedicalRecord from '../views/AddRecord/AddMedicalRecord';
import PaymentSuccessPage from 'views/PaymentPage/PaymentSuccessPage';
import PaymentFailedPage from 'views/PaymentPage/PaymentFailedPage';
import { appConfiguration } from 'Constants/appConfiguration';
import ViewReportLogin from 'views/pages/authentication/ViewReportLogin/ViewReportLogin';

// routes
const TermsOfUse = Loadable(lazy(() => import('views/Footer/FooterPages/TermsOfUse')));
const ForgotPassword = Loadable(lazy(() => import('views/ForgotPassword/ForgotPassword')));
const HomeDashBoard = Loadable(lazy(() => import('views/HomeDashBoard/HomeDashBoard')));

const MarketPlace = Loadable(lazy(() => import('views/MarketPlace/MarketPlace')));
const MedicalBin = Loadable(lazy(() => import('views/MedicalBin/MedicalBin')));
const ViewMedicalPlan = Loadable(lazy(() => import('views/MedicalPlan/ViewMedicalPlan/ViewMedicalPlan')));
const MedicalRecord = Loadable(lazy(() => import('views/MedicalRecord/MedicalRecord')));
const MedicalShareDetailView = Loadable(lazy(() => import('views/MedicalShareView/MedicalShareDetailView/MedicalShareDetailView')));
const MedicalShareRecordList = Loadable(lazy(() => import('views/MedicalShareView/MedicalShareRecordList/MedicalShareRecordList')));
const MedicalShareView = Loadable(lazy(() => import('views/MedicalShareView/MedicalShareView')));
const ChangePin = Loadable(lazy(() => import('views/MyProfile/ChangePin/ChangePin')));
const MyProfile = Loadable(lazy(() => import('views/MyProfile/MyProfile')));
const Login = Loadable(lazy(() => import('views/pages/authentication/Login/Login')));
const Registration = Loadable(lazy(() => import('views/pages/authentication/Registration/Registration')));
const Home = Loadable(lazy(() => import('views/pages/Home/Home')));
const OurSolution = Loadable(lazy(() => import('views/pages/OurSolution/OurSolution')));
const Privacy = Loadable(lazy(() => import('views/pages/Privacy/Privacy')));
const Blog = Loadable(lazy(() => import('views/pages/Welcome/Blog/Blog')));
const Post = Loadable(lazy(() => import('views/pages/Welcome/Blog/blog-content/Post')));
const InstantAccessMedicalRecords = Loadable(lazy(() => import('views/pages/Welcome/InstantAccess/InstantAccessMedicalRecords')));
const RecordDetails = Loadable(lazy(() => import('views/pages/Welcome/InstantAccess/RecordDetails')));
const PatientAccess = Loadable(lazy(() => import('views/pages/Welcome/PatientAccess/PatientAccess')));
const Article = Loadable(lazy(() => import('views/pages/Welcome/Article/Article')));
const ArticleDetails = Loadable(lazy(() => import('views/pages/Welcome/Article/ArticleDetails')));
const HowItWorks = Loadable(lazy(() => import('views/pages/HowItWorks/HowItWorks')));
const FrenchPage = Loadable(lazy(() => import('views/pages/Welcome/FrenchPage/FrenchPage')));
const ResetPasswordPage = Loadable(lazy(() => import('views/ResetPasssword/ResetPasswordPage')));
const MedicalHistory = Loadable(lazy(() => import('views/MedicalHistory/MedicalHistory')));
const InstantShare = Loadable(lazy(() => import('views/pages/authentication/InstantShare/InstantShare')));
const Services = Loadable(lazy(() => import('views/pages/Welcome/Services/Services')));
const ServicesDetails = Loadable(lazy(() => import('views/pages/Welcome/Services/ServicesDetails')));
const ServiceOrderHistory = Loadable(lazy(() => import('views/ServiceOrderHIstory/ServiceOrderHistory')));

const routes = () => {
    const authRole = config.ROLES;

    const ROLES = {
        patient: authRole[0].roleId,
        doctor: authRole[1].roleId,
        share: authRole[2].roleId,
        instant_share: authRole[3].roleId
    };

    const getCommonRoute = () => {
        if (appConfiguration.haveGadMedical) {
            return {
                element: <PublicRoutes component={<PublicLayout />} />,
                children: [
                    {
                        path: 'view-report',
                        element: <PublicRoutes component={<ViewReportLogin />} />
                    }
                ]
            };
        }
        return {
            // path: 'home',
            element: <PublicRoutes component={<PublicLayout />} />,
            children: [
                // {
                //     path: '/',
                //     element: <Navigate to={'home'} />
                // },
                {
                    path: 'login',
                    element: <PublicRoutes component={<Login />} />
                },
                {
                    path: 'register',
                    element: <PublicRoutes component={<Registration />} />
                },
                {
                    path: 'user/forgotPassword',
                    element: <PublicRoutes component={<ForgotPassword />} />
                },
                {
                    path: 'user/resetPassword/:uid/:token',
                    element: <PublicRoutes component={<ResetPasswordPage />} />
                },
                {
                    path: 'userrecord/view-shared-record/:Id',
                    element: <PublicRoutes component={<MedicalShareView />} />
                },
                {
                    path: 'articles',
                    element: <PublicRoutes component={<Article />} />
                },
                {
                    path: 'services',
                    element: <PublicRoutes component={<Services />} />
                },
                {
                    path: 'services/:serviceName',
                    element: <PublicRoutes component={<ServicesDetails />} />
                },
                {
                    path: 'articles/:id',
                    element: <PublicRoutes component={<ArticleDetails />} />
                },
                {
                    path: 'blog',
                    element: <PublicRoutes component={<Blog />} />
                },
                {
                    path: 'blog/post/:id',
                    element: <PublicRoutes component={<Post />} />
                },
                {
                    path: 'doctor-access',
                    element: <PublicRoutes component={<InstantShare />} />
                },
                {
                    path: 'patient-access',
                    element: <PublicRoutes component={<PatientAccess />} />
                },
                {
                    path: 'how-it-works',
                    element: <PublicRoutes component={<HowItWorks />} />
                },
                {
                    path: 'home',
                    element: <PublicRoutes component={<Home />} />
                },
                {
                    path: 'payment-success',
                    element: <PublicRoutes component={<PaymentSuccessPage />} />
                },
                {
                    path: 'payment-failed',
                    element: <PublicRoutes component={<PaymentFailedPage />} />
                },
                {
                    path: 'french',
                    element: <PublicRoutes component={<FrenchPage />} />
                },
                // {
                //     path: 'instant-access-medical-records',
                //     element: <PublicRoutes component={<InstantAccessMedicalRecords />} />
                // },
                // {
                //     path: 'instant-access-medical-records/:slug',
                //     element: <PublicRoutes component={<RecordDetails />} />
                // },
                {
                    path: 'our-solution',
                    element: <PublicRoutes component={<OurSolution />} />
                },
                {
                    path: 'privacy',
                    element: <PublicRoutes component={<Privacy />} />
                },
                {
                    path: 'privacy-policy',
                    element: <PublicRoutes component={<PrivacyPolicy />} />
                },
                {
                    path: 'terms-of-use',
                    element: <PublicRoutes component={<TermsOfUse />} />
                },
                {
                    path: 'gdpr-policy',
                    element: <PublicRoutes component={<GdprPolicy />} />
                }
            ]
        };
    };

    return [
        {
            path: 'app',
            element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<AuthorisedLayout />} />,
            children: [
                // {
                //     path: '',
                //     element: <Navigate to={'app/dashboard'} />
                // },
                {
                    path: 'dashboard',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<HomeDashBoard />} />
                },
                {
                    path: 'newMedicalRecord',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<AddMedicalRecord />} />
                },
                {
                    path: 'medical-record',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<MedicalRecord />} />
                },
                {
                    path: 'diagnosing-timeline',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<DiagnosingTimeline />} />
                },
                {
                    path: 'market-place',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<MarketPlace />} />
                },
                {
                    path: 'bin',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<MedicalBin />} />
                },
                {
                    path: 'faq',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<Faq />} />
                },
                {
                    path: 'contact-us',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<ContactUs />} />
                },
                {
                    path: 'edit/:editId',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<EditMedicalRecord />} />
                },
                {
                    path: 'change-pin',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<ChangePin />} />
                },
                {
                    path: 'myprofile',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<MyProfile />} />
                },
                {
                    path: 'order-history',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<ServiceOrderHistory />} />
                },
                // Medical Plan
                {
                    path: 'view-medical-plan',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<ViewMedicalPlan />} />
                },

                // Medical Routes
                {
                    path: 'medical-history',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<MedicalHistory />} />
                },
                {
                    path: 'medical-history/:medicalOption',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<MedicalHistory />} />
                },
                // Footer Routes
                {
                    path: 'gdpr-policy',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<GdprPolicy />} />
                },
                {
                    path: 'privacy-policy',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<PrivacyPolicy />} />
                },
                {
                    path: 'terms-of-use',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<TermsOfUse />} />
                },
                // medical services
                {
                    path: 'services',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<Services />} />
                },
                {
                    path: 'services/:serviceName',
                    element: <PrivateRoutes allowedRoles={[ROLES.patient]} component={<ServicesDetails />} />
                }
            ]
        },
        // share Routes
        {
            path: 'share',
            element: <PrivateRoutes allowedRoles={[ROLES.share]} component={<AuthorisedLayout />} />,
            children: [
                // {
                //     path: '/',
                //     element: <Navigate to={'share/view-shared-record-list'} />
                // },
                {
                    path: 'view-shared-record-list',
                    element: <PrivateRoutes allowedRoles={[ROLES.share]} component={<MedicalShareRecordList />} />
                },
                {
                    path: 'view-shared-record/:medicalId',
                    element: <PrivateRoutes allowedRoles={[ROLES.share]} component={<MedicalShareDetailView />} />
                }
            ]
        },

        //instant access Routes
        {
            path: 'instant',
            element: <PrivateRoutes allowedRoles={[ROLES.instant_share]} component={<AuthorisedLayout />} />,
            children: [
                // {
                //     path: '/',
                //     element: <Navigate to={'instant/instant-access-medical-records'} />
                // },
                {
                    path: 'instant-access-medical-records',
                    element: <PrivateRoutes allowedRoles={[ROLES.instant_share]} component={<InstantAccessMedicalRecords />} />
                },
                {
                    path: 'instant-access-medical-records/:editId',
                    element: <PrivateRoutes allowedRoles={[ROLES.instant_share]} component={<EditMedicalRecord />} />
                }
            ]
        },

        // common Routes
        getCommonRoute(),

        {
            path: '*',
            element: <Navigate to={appConfiguration.haveGadMedical ? 'view-report' : 'home'} />
        }
    ];
};

export default routes;
