const layout = 'app';
import ImageIcon from '@mui/icons-material/Image';

const instantShareMenu = {
    id: 'instantShareMenu',
    // title: 'Main Menu',
    type: 'group',
    children: [
        // dashboard
        {
            id: 'instantshare',
            title: 'Instant Share',
            type: 'item',
            url: `/instant/instant-access-medical-records`,
            icon: ImageIcon,
            breadcrumbs: false
        }
    ]
};

export default instantShareMenu;
