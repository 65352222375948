import { AES, SHA256, enc } from 'crypto-js';
import LocalStorage from './LocalStorage';

const getUser = () => {
    const userStr = localStorage.getItem('Data');
    if (userStr) return JSON.parse(userStr);
    else return null;
};

const getToken = () => {
    return localStorage.getItem('UserToken') || null;
};

const setUserSession = (token, user) => {
    localStorage.setItem('UserToken', token);
    localStorage.setItem('Data', JSON.stringify(user));
};

const removeUserSession = () => {
    localStorage.removeItem('UserToken');
    localStorage.removeItem('Data');
};

const charValidate = (text, limit) => {
    if (text !== undefined && limit !== undefined && text && limit) {
        if (text.length > limit) {
            return `${text.substring(0, limit - 2)}...`;
        } else {
            return `${text.substring(0, limit)}`;
        }
    } else {
        return '';
    }
};

const authHeader = {
    headers: {
        Authorization: `Bearer ${LocalStorage.getUserToken()}`
    }
};

const encryptData = (data, secretKey) => {
    return AES.encrypt(data, secretKey).toString();
};

const decryptData = (data, secretKey) => {
    return AES.decrypt(data, secretKey).toString(enc.Utf8);
};

const carousel = (items) => {
    let unsubscribe;
    if (items.length > 0) {
        let myIndex = 0;
        const carousel = () => {
            for (let i = 0; i < items.length; i++) {
                items[i].style.display = 'none';
            }
            myIndex++;
            if (myIndex > items.length) {
                myIndex = 1;
            }
            items[myIndex - 1].style.display = 'block';
            unsubscribe = setTimeout(carousel, 4000);
        };
        carousel();
    }
    return unsubscribe;
};

export { getUser, getToken, setUserSession, removeUserSession, charValidate, authHeader, encryptData, decryptData, carousel };
