import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import LoginUserReducer from './LoginUser/LoginUserReducer';
import deleteRecordReducer from './MedicalBin/medicalBinReducer';
import medicalRecordReducer from './MedicalRecord/medicalRecordReducer';
import medicalRecordDetailReducer from './MedicalRecordDetails/medicalRecordDetailsReducer';
import BlogReducer from './Blog/BlogReducer';
import medicalConditionReducer from './MedicalHistory/MedicalCondition/medicalConditionReducer';
import medicalAllergyReducer from './MedicalHistory/MedicalAllergy/medicalAllergyReducer';
import medicalVitalReducer from './MedicalHistory/MedicalVitals/medicalVitalReducer';
import serviceOrderHistoryReducer from './ServiceOrderHistory/serviceOrderHistoryReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    medicalRecord: medicalRecordReducer,
    medicalRecordDetail: medicalRecordDetailReducer,
    medicalDeleteRecord: deleteRecordReducer,
    userSession: LoginUserReducer,
    blogPost: BlogReducer,
    // Medical History
    medicalCondition: medicalConditionReducer,
    medicalAllergy: medicalAllergyReducer,
    medicalVital: medicalVitalReducer,
    // OrderHistory
    serviceOrderHistory: serviceOrderHistoryReducer
});

export default reducer;
