import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Backdrop,
    Button,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SpeedDial,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import SideBarIconItem from './SideBarIconItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box } from '@mui/system';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' }
];

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const SideBarIconCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    // const handleClick = () => {
    //     setOpen(!open);
    //     setSelected(!selected ? menu.id : null);
    // };

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        if (!item.isRouteEnabled) {
            switch (item.type) {
                case 'collapse':
                    return <SideBarIconCollapse key={item.id} menu={item} level={level + 1} />;
                case 'item':
                    return <SideBarIconItem key={item.id} item={item} level={level + 1} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <ListItemButton>
            {menu.icon ? (
                <Icon
                    strokeWidth={1.5}
                    size="1.3rem"
                    onClick={handleClick}
                    onMouseOver={handleClick}
                    // style={{ marginTop: 'auto', marginBottom: 'auto' }}
                />
            ) : (
                <FiberManualRecordIcon
                    sx={{
                        width: selected === menu.id ? 8 : 6,
                        height: selected === menu.id ? 8 : 6
                    }}
                    fontSize={level > 0 ? 'inherit' : 'medium'}
                />
            )}

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <MenuItem>{menus}</MenuItem>
            </Menu>
        </ListItemButton>
    );
};

SideBarIconCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default SideBarIconCollapse;
