import axios from 'axios';
import { constants } from 'Constants/constants';
import { useNavigate } from 'react-router';
import { store } from 'store';
import { getdata } from 'utils/apiCalling';
import { authHeader } from 'utils/common';
import { addErrorMessage } from 'utils/LoadingContent';
import * as constant from './medicalRecordTypes';

export const fetchMedicalRecordRequest = () => {
    return {
        type: constant.FETCH_MEDICAL_RECORD_REQUEST
    };
};

export const fetchMedicalRecordSuccess = (records) => {
    return {
        type: constant.FETCH_MEDICAL_RECORD_SUCCESS,
        payload: records
    };
};

export const fetchMedicalRecordFailure = (error) => {
    return {
        type: constant.FETCH_MEDICAL_RECORD_FAILURE,
        payload: error
    };
};

export const addMedicalNewRecordSuccess = (data) => {
    return {
        type: constant.ADD_NEW_MEDICAL_RECORD,
        payload: data
    };
};

export const deleteMedicalRecordSuccess = (index) => {
    return {
        type: constant.DELETE_MEDICAL_RECORD_SUCCESS,
        payload: index
    };
};

export const deleteMultipleMedicalRecordSuccess = (medicalRecord) => {
    return {
        type: constant.DELETE_MULTIPLE_MEDICAL_RECORD_SUCCESS,
        payload: medicalRecord
    };
};

export const deleteMedicalRecordFailure = (eroor) => {
    return {
        type: constant.DELETE_MEDICAL_RECORD_FAILURE,
        payload: eroor
    };
};

// Get the medical records
export const fetchMedicalRecord = (isInstantShare = false) => {
    const medicalRecordUrl = isInstantShare ? constants.getUserAllRecordFromInstantShare : constants.gerUserAllRecord;
    return (dispatch) => {
        dispatch(fetchMedicalRecordRequest());
        getdata(medicalRecordUrl)
            .catch((err) => {
                addErrorMessage('Something went wrong');
                dispatch(fetchMedicalRecordFailure(err));
            })
            .then((response) => {
                const medicalRecord = response.data;
                dispatch(fetchMedicalRecordSuccess(medicalRecord));
            });
    };
};

export const fetchLatestRecord = () => {
    return (dispatch) => {
        dispatch(fetchMedicalRecordRequest());
        axios
            .get(constants.gerUserLatestRecord, authHeader)
            .then((response) => {
                const medicalRecord = response.data;
                dispatch(fetchMedicalRecordSuccess(medicalRecord));
            })
            .catch((err) => {
                console.log(err);
                // addErrorMessage('Something went wrong');
                dispatch(fetchMedicalRecordFailure(err));
            });
    };
};

export const fetchShareMedicalRecord = (userToken) => {
    return (dispatch) => {
        dispatch(fetchMedicalRecordRequest());
        axios
            .get(constants.baseURL + 'shared-records/', {
                headers: {
                    // "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then((response) => {
                const medicalRecord = response.data;
                dispatch(fetchMedicalRecordSuccess(medicalRecord));
            })
            .catch((err) => {
                dispatch(fetchMedicalRecordFailure(true));
            });
    };
};

export const deleteMedicalRecordById = (index) => (dispatch) => {
    return dispatch(deleteMedicalRecordSuccess(index));
};

export const deleteMultipleMedicalRecord = (medicalRecord) => (dispatch) => {
    return dispatch(deleteMultipleMedicalRecordSuccess(medicalRecord));
};

export const addMedicalRecord = (data) => (dispatch) => {
    return dispatch(addMedicalNewRecordSuccess(data));
};
