// material-ui
import { Typography } from '@mui/material';
// project imports
import menuItems from 'menu-items';
import SideBarIconNavGroup from './Components.js/SideBarIconNavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const SideBarIconList = () => {
    const navItems = menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <SideBarIconNavGroup key={item.id} item={item} />;
            case 'sibebarFalse':
                return;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default SideBarIconList;
