import axios from 'axios';
import { constants } from 'Constants/constants';
import { SET_ARTICLES, SET_ARTICLES_VIDEOS, SET_BLOG_POSTS } from 'store/Blog/BlogActionTypes';
import { setUserInformation } from 'store/LoginUser/LoginUserAction';
import { FETCH_MEDICAL_RECORD_SUCCESS } from 'store/MedicalRecord/medicalRecordTypes';
import { authHeader } from './common';

async function getdata(URL, header = true) {
    try {
        let response = await axios.get(URL, header && authHeader);
        return response;
    } catch (error) {
        return error;
    }
}

async function postDataApi(URL, data, header = false) {
    try {
        let response = await axios
            .post(URL, data, header && authHeader)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
        return response;
    } catch (error) {
        return error;
    }
}

async function patchDataApi(URL, data, header) {
    try {
        let response = await axios
            .patch(URL, data, header)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
        return response;
    } catch (error) {
        return error;
    }
}

async function putDataApi(URL, data, header = true) {
    try {
        let response = await axios
            .put(URL, data, header && authHeader)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
        return response;
    } catch (error) {
        return error;
    }
}

async function deleteDataApi(URL, header = true) {
    try {
        let response = await axios
            .delete(URL, header && authHeader)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
        return response;
    } catch (error) {
        return error;
    }
}

const fetchAPI = (URL, method, param) => {
    var requestOptions = {
        method: method,
        body: param
    };
    let res = fetch(URL, requestOptions)
        .then((response) => response.text())
        .then((response) => {
            try {
                return JSON.parse(response);
            } catch (error) {
                console.log(error);
            }
        })
        .catch((error) => console.log('error', error));
    return res;
};

const getPosts = async (dispatch) => {
    const response = await getdata(process.env.REACT_APP_BLOGGER_POST_AUTH, '');
    if (response.status !== undefined && response.status === 200) {
        if (response.data !== undefined && response.data) {
            if (response.data.items === undefined || response.data.items.length === 0) {
                setTimeout(() => {
                    dispatch({ type: SET_BLOG_POSTS, payload: null });
                });
            } else {
                let arr = [];
                response.data.items.forEach((item) => {
                    arr.push({
                        ...item,
                        postThumbnail: `${item.content.substring(item.content.indexOf('https'), item.content.indexOf('.jpg'))}.jpg`
                    });
                });
                setTimeout(() => {
                    dispatch({ type: SET_BLOG_POSTS, payload: arr });
                });
            }
        }
    }
};

const getInstantAccessMedicalRecords = async (URL, method, responseData, dispatch, navigate) => {
    var requestOptions = {
        method: method,
        headers: {
            Authorization: `Bearer ${responseData.token}`
        }
    };
    const response = await fetch(URL, requestOptions)
        .then((res) => res.text())
        .then((res) => JSON.parse(res))
        .catch((err) => err);

    if (response !== undefined && response) {
        dispatch({ type: FETCH_MEDICAL_RECORD_SUCCESS, payload: response.records });
        setTimeout(() => {
            const item = {
                key: responseData.token,
                user: {
                    id: 0,
                    email: '',
                    fname: '',
                    lname: '',
                    phone_number: '',
                    pro_member: false,
                    mdrx_id: '',
                    role_id: responseData.role_id
                },
                cookies: []
            };
            dispatch(setUserInformation(item));
            sessionStorage.setItem('userSession', JSON.stringify(item));
            navigate('/instant', { state: { patient_name: response.patient_name } });
            window.location.reload();
        }, 1000);
    }
};

const getArticlesData = async (dispatch, isGetArticles) => {
    const response = await getdata(isGetArticles ? constants.getArticles : constants.getArticleVideos, '');
    if (response.status !== undefined && response.status === 200) {
        if (response.data !== undefined && response.data) {
            if (response.data.results === undefined || response.data.results.length === 0) {
                if (isGetArticles) {
                    setTimeout(() => {
                        dispatch({ type: SET_ARTICLES, payload: null });
                    });
                } else {
                    setTimeout(() => {
                        dispatch({ type: SET_ARTICLES_VIDEOS, payload: null });
                    });
                }
            } else {
                if (isGetArticles) {
                    setTimeout(() => {
                        dispatch({ type: SET_ARTICLES, payload: response.data.results });
                    });
                } else {
                    setTimeout(() => {
                        dispatch({ type: SET_ARTICLES_VIDEOS, payload: response.data.results });
                    });
                }
            }
        }
    }
};

export {
    getdata,
    postDataApi,
    patchDataApi,
    putDataApi,
    deleteDataApi,
    fetchAPI,
    getPosts,
    getInstantAccessMedicalRecords,
    getArticlesData
};
