const FETCH_MEDICAL_CONDITION_REQUEST = 'FETCH_MEDICAL_CONDITION_REQUEST';
const FETCH_MEDICAL_CONDITION_SUCCESS = 'FETCH_MEDICAL_CONDITION_SUCCESS';
const FETCH_MEDICAL_CONDITION_FAILURE = 'FETCH_MEDICAL_CONDITION_FAILURE';
const ADD_MEDICAL_CONDITION = 'ADD_MEDICAL_CONDITION';
const UPDATE_MEDICAL_CONDITION = 'UPDATE_MEDICAL_CONDITION';

export {
    FETCH_MEDICAL_CONDITION_REQUEST,
    FETCH_MEDICAL_CONDITION_SUCCESS,
    FETCH_MEDICAL_CONDITION_FAILURE,
    ADD_MEDICAL_CONDITION,
    UPDATE_MEDICAL_CONDITION
};
