import * as constant from './medicalRecordDetailsTypes';

const initialState = {
    loading: false,
    medicalDetail: [],
    error: ''
};

const medicalRecordDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.FETCH_MEDICAL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_MEDICAL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalDetail: action.payload,
                error: ''
            };

        case constant.FETCH_MEDICAL_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                medicalDetail: [],
                error: true
            };
        case constant.RENAME_MEDICAL_FILE_NAME:
            const FinalData = changeValue(state.medicalDetail, action.payload);
        // return {
        //     ...state,
        //     medicalDetail:
        // };
        case constant.UPDATE_MEDICAL_FIELD:
            const jsonData = action.payload;
            return {
                ...state,
                medicalDetail: { ...state.medicalDetail, ...jsonData }
            };

        case constant.DELETE_MEDICAL_FILE:
            /**
             * @action.payload ---> Medical Id
             * @state.medicalDetail.files ---> Medical File
             */
            const files = getFiles(action.payload, state.medicalDetail?.files);
            console.log({ files });
            return {
                ...state,
                medicalDetail: { ...state.medicalDetail, files: files }
            };
        default:
            return state;
    }
};
export default medicalRecordDetailReducer;

const getFiles = (medicalID, medicalFiles) => {
    const fileIndex = medicalFiles.findIndex((item) => item?.id === medicalID);
    if (fileIndex !== -1) medicalFiles.splice(fileIndex, 1);
    return medicalFiles;
};
