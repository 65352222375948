import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function CustomResponsiveCarousel(props) {
    const { children, isShowArrows = false, isShowThumbs = false, isAutoPlay = false } = props;

    return (
        <Carousel
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            verticalSwipe="standard"
            swipeable
            showArrows={isShowArrows}
            showThumbs={isShowThumbs}
            autoPlay={isAutoPlay}
        >
            {children}
        </Carousel>
    );
}

export default CustomResponsiveCarousel;
