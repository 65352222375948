/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// material-ui core components
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './FooterStyle';
import { NavLink } from 'react-router-dom';
import FooterRoutes from 'menu-items/FooterRoutes';
import { projectVersion } from 'Constants/constants';

const useStyles = makeStyles(styles);

export default function Footer() {
    const classes = useStyles();
    const aClasses = classNames({
        [classes.a]: true
    });
    return (
        <footer
            className={classes.footer}
            style={{
                position: 'fixed',
                left: '0',
                bottom: 0,
                width: '100%',
                height: '30px',
                backgroundColor: '#f2f2f2',
                textAlign: 'center'
            }}
        >
            <div className={classes.container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        {FooterRoutes.children.map((item, index) => {
                            return (
                                <ListItem className={classes.inlineBlock} key={index}>
                                    <NavLink to={item.url} className={classes.block}>
                                        {item.title}
                                    </NavLink>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
                <div className={classes.right}>
                    &copy; {1900 + new Date().getYear()} &nbsp;, &nbsp;
                    <a href="/admin" className={aClasses}>
                        MdRx
                    </a>
                    &nbsp; {projectVersion.version} &nbsp; , &nbsp; LLC. All rights reserved.
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool
};
