import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function CustomProgressBar(props) {
    const { type, value } = props;
    const getProgressType = (type) => {
        if (type === 'circular') return <CircularProgress style={{ color: '#FEE589' }} variant="determinate" value={value} />;
        else if (type === 'linear') return <LinearProgress style={{ color: '#FEE589' }} variant="determinate" value={value} />;
        else return <LinearProgress style={{ color: '#FEE589' }} variant="determinate" value={value} />;
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>{getProgressType(type, value)}</Box>
            <Box>
                <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}

CustomProgressBar.defaultProps = {
    type: 'linear'
};
CustomProgressBar.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['circular', 'linear']).isRequired
};
