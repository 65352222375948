/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
    cardBorder: {
        backgroundColor: ' rgb(255, 255, 255)',
        color: 'rgb(97, 97, 97)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: 'none',
        backgroundImage: 'none',
        borderRadius: '8px',
        overflow: 'hidden',
        border: `1px solid #90caf942`
    }
}));

export default function CustomCard(props) {
    const classes = useStyle();
    const { children, ...rest } = props;
    return (
        <Card className={classes.cardBorder} {...rest}>
            {children}
        </Card>
    );
}
