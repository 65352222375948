import axios from 'axios';
import LocalStorage from './LocalStorage';
import { constants } from 'Constants/constants';

let isRefreshing = false;

// Function to refresh the token
const refreshToken = async () => {
    try {
        const refreshToken = LocalStorage.getLocalRefreshToken(); // Replace with the actual key you use for storing the refresh token
        const formData = new FormData();
        formData.append('refresh', refreshToken);

        // Make a request to refresh the token using your API's endpoint and method
        const response = await axios.post(constants.baseURL + 'auth/token/refresh/', formData);
        const { access, refresh } = response.data;
        // Assuming your API returns a new access token and expiration time
        const newAccessToken = access;
        const newTokenExpiration = refresh;

        // Update the access token and token expiration in your preferred storage mechanism
        // Replace 'yourAccessTokenStorageKey' and 'yourTokenExpirationKey' with the actual keys you use for storing the access token and expiration time
        LocalStorage.setToken(newAccessToken);
        LocalStorage.setLocalRefreshToken(newTokenExpiration);

        // Execute all the pending requests
        // refreshSubscribers.forEach((subscriber) => subscriber(newAccessToken));
        // refreshSubscribers = [];
    } catch (error) {
        // Handle the error
        console.log('Error refreshing token:', error);
    } finally {
        isRefreshing = false;
    }
};

export default refreshToken;

export const isTokenAboutToExpire = () => {
    const token = LocalStorage.getUserDetails();
    if (!token) {
        return false; // No token available, consider it as expired
    }

    const expirationTime = token.exp * 1000; // Convert expiration time to milliseconds
    const currentTime = Date.now();
    const remainingTime = expirationTime - currentTime;

    // Define the threshold time (in milliseconds) for considering the token as about to expire
    const thresholdTime = 180 * 1000; // 3 minute

    return remainingTime <= thresholdTime;
};
