import FooterRoutes from './FooterRoutes';
import { getDashboardMenu } from 'views/utilities/utils';

// ==============================|| MENU ITEMS ||============================== //

const updateMenu = getDashboardMenu();
const menuItems = {
    items: [updateMenu, FooterRoutes]
};

export default menuItems;
