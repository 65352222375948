import ShareIcon from '@mui/icons-material/Share';
const layout = 'app';

const publicRoutesMenu = {
    id: 'publicRoutesMenu',
    // title: 'Main Menu',
    type: 'group',
    children: [
        // dashboard
        {
            id: 'homeId',
            title: 'Home',
            type: 'item',
            url: `home`,
            // icon: HomeIcon,
            breadcrumbs: false
        },
        {
            id: 'howitworksId',
            title: 'How it Works',
            type: 'item',
            url: `how-it-works`,
            // icon: ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'oursolutionId',
            title: 'Our Solution',
            type: 'item',
            url: `our-solution`,
            // icon: ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'articlesId',
            title: 'Resources',
            type: 'item',
            url: `articles`
            // icon: PersonIcon,
        },
        // {
        //     id: 'articlesId',
        //     title: 'Blog',
        //     type: 'item',
        //     url: `blog`
        //     // icon: PersonIcon,
        // },
        // {
        //     id: 'ResourcesId',
        //     title: 'Resources',
        //     type: 'collapse',
        //     // icon: SettingsApplicationsIcon,
        //     children: [
        //         {
        //             id: 'articlesId',
        //             title: 'Articles',
        //             type: 'item',
        //             url: `/articles`
        //             // icon: PersonIcon,
        //         }
        //     ]
        // },
        {
            id: 'privacyId',
            title: 'Privacy',
            type: 'item',
            url: `privacy`,
            // icon: ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'servicesId',
            title: 'Services',
            type: 'item',
            url: `/services`,
            // icon: ArticleIcon,
            breadcrumbs: false
        }
    ]
};

export default publicRoutesMenu;
