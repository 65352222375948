/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, ListItemButton, ListItemIcon, Tooltip, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';
import UpgradePlanCard from '../Header/ProfileSection/UpgradePlanCard';
import Logout from 'components/Logout/Logout';
import SideBarIconList from './SibeBarIconList/SideBarIconList';
import SideBarIconItem from './SibeBarIconList/Components.js/SideBarIconItem';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LogoutIcon from '@mui/icons-material/Logout';
import { getUserRole } from 'views/utilities/utils';
import LocalStorage from 'utils/LocalStorage';
// ==============================|| SIDEBAR DRAWER ||============================== //

const iconLogOut = () => {
    const handleLogout = async () => {
        LocalStorage.removeUser();
        window.location.reload();
    };

    return (
        <ListItemButton onClick={() => handleLogout()} style={{ position: 'absolute', bottom: 0 }}>
            <ListItemIcon>
                <Tooltip title={'Log Out'} placement="right-start">
                    <LogoutIcon />
                </Tooltip>
            </ListItemIcon>
        </ListItemButton>
    );
};

const Sidebar = ({ drawerOpen, drawerToggle, window, shortWindow }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    {matchUpMd ? (
                        drawerOpen && shortWindow ? (
                            <ListItemButton>
                                <Tooltip title={'MdRx'} placement="right-start">
                                    <MedicalServicesIcon fontSize="large" htmlColor={theme.palette.primary.main} />
                                </Tooltip>
                            </ListItemButton>
                        ) : (
                            <LogoSection />
                        )
                    ) : (
                        <LogoSection />
                    )}
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    {/* {drawerOpen && !shortWindow && matchUpMd ? <MenuList /> : <SideBarIconList />} */}

                    {matchUpMd ? drawerOpen && shortWindow ? <SideBarIconList /> : <MenuList /> : <MenuList />}
                    {/* {matchUpMd
                        ? drawerOpen && shortWindow
                            ? ''
                            : getUserRole() === 'patient' && <UpgradePlanCard />
                        : getUserRole() === 'patient' && <UpgradePlanCard />} */}

                    {matchUpMd ? drawerOpen && shortWindow ? iconLogOut() : <Logout /> : <Logout />}
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                    {/* {getUserRole() === 'patient' && <UpgradePlanCard />} */}

                    <Logout />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: matchUpMd ? (drawerOpen && shortWindow ? '100px' : drawerWidth) : 'auto' }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: matchUpMd ? (drawerOpen && shortWindow ? '100px' : drawerWidth) : drawerWidth,
                        background: theme.palette.primary.light,
                        color: theme.palette.text.primary,
                        borderRight: 'none'

                        // [theme.breakpoints.up('md')]: {
                        //     top: '88px'
                        // }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
