import * as constant from './BlogActionTypes';

const initialState = {
    selectedPost: null,
    blogPosts: null,
    articles: null,
    articleVideos: null
};

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.SET_BLOG_POST_SELECTION:
            return {
                ...state,
                selectedPost: action.payload
            };
        case constant.SET_BLOG_POSTS:
            return {
                ...state,
                blogPosts: action.payload
            };
        case constant.SET_ARTICLES:
            return {
                ...state,
                articles: action.payload
            };
        case constant.SET_ARTICLES_VIDEOS:
            return {
                ...state,
                articleVideos: action.payload
            };
        default:
            return state;
    }
};

export default BlogReducer;
