export const colors = {
    white: '#fff',
    themeFont1: '#1D2130BF',
    themeFont2: '#031432',
    themeFont3: '#151515',
    themeFont4: '#1D2130',
    themeFont5: '#7D7987CC',
    themeFont6: '#2A3342',
    themeFont7: '#83858D',
    themeFont8: '#263238',
    themeFont9: '#636A70',
    themeColor1: '#0EA1D7',
    themeColor2: '#0EA1D71A',
    themeColor3: '#2196F3',
    themeColor4: '#DEEFFD',
    themeColor5: '#E9F5FE',
    themeColor6: '#D3EAFD',
    themeColor7: '#D9D9D9',
    shadowColor1:
        'linear-gradient(90deg, rgba(0, 0, 0, 0.85) -20.45%, rgba(0, 0, 0, 0.85) -8.86%, rgba(0, 0, 0, 0.75) 16.88%, rgba(0, 0, 0, 0) 105.69%)'
};
