import * as constant from './medicalAllergyTypes';

const initialState = {
    loading: false,
    medicalAllergy: [],
    error: ''
};

const medicalAllergyReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.FETCH_MEDICAL_ALLERGY_REQUEST:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_MEDICAL_ALLERGY_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalAllergy: action.payload,
                error: ''
            };

        case constant.FETCH_MEDICAL_ALLERGY_FAILURE:
            return {
                ...state,
                loading: false,
                medicalAllergy: [],
                error: action.payload
            };

        case constant.ADD_MEDICAL_ALLERGY:
            return {
                ...state,
                loading: false,
                medicalAllergy: [...state.medicalAllergy, action.payload],
                error: ''
            };

        case constant.UPDATE_MEDICAL_ALLERGY:
            return {
                ...state,
                loading: false,
                medicalAllergy: action.payload,
                error: ''
            };

        default:
            return state;
    }
};
export default medicalAllergyReducer;
