import config from 'config';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

export default function ThemeRoutes() {
    const { userDetails } = useSelector((state) => state.userSession);

    const auth = {
        roles: [userDetails?.role_id]
    };

    const routing = useRoutes(routes(auth.roles));
    return <>{routing}</>;
}
