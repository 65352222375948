import * as constant from './medicalConditionTypes';

const initialState = {
    loading: false,
    medicalCondition: [],
    error: ''
};

const medicalConditionReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.FETCH_MEDICAL_CONDITION_REQUEST:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_MEDICAL_CONDITION_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalCondition: action.payload,
                error: ''
            };

        case constant.FETCH_MEDICAL_CONDITION_FAILURE:
            return {
                ...state,
                loading: false,
                medicalCondition: [],
                error: action.payload
            };

        case constant.ADD_MEDICAL_CONDITION:
            return {
                ...state,
                loading: false,
                medicalCondition: [...state.medicalCondition, action.payload],
                error: ''
            };

        case constant.UPDATE_MEDICAL_CONDITION:
            return {
                ...state,
                loading: false,
                medicalCondition: action.payload,
                error: ''
            };

        default:
            return state;
    }
};
export default medicalConditionReducer;
