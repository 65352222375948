var storage = window.sessionStorage;
import jwt_decode from 'jwt-decode';

const LocalStorage = {
    setToken(token) {
        return storage.setItem('user_obj', token);
    },
    getLocalRefreshToken() {
        const accessToken = storage.getItem('refreshToken');
        return accessToken;
    },
    setLocalRefreshToken(token) {
        const refreshToken = storage.setItem('refreshToken', token);
        return refreshToken;
    },
    getUserToken() {
        return storage.getItem('user_obj') && storage.getItem('user_obj');
    },
    getUserDetails() {
        return storage.getItem('user_obj') && jwt_decode(storage.getItem('user_obj'));
    },
    setLang(obj) {
        obj && storage.setItem('lang', JSON.stringify(obj));
    },
    getLang() {
        return JSON.parse(storage.getItem('lang'));
    },
    deleteLang() {
        storage.removeItem('lang');
    },
    removeUser() {
        storage.removeItem('user_obj');
        storage.removeItem('refreshToken');
        sessionStorage.clear();
    },
    getLogout() {
        this.removeUser();
    }
};

export default LocalStorage;
