/* eslint-disable react-hooks/rules-of-hooks */
import {
    Button,
    Card,
    CardHeader,
    Container,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    iconClasses,
    MenuItem,
    Paper,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import CustomTextField from 'components/Controls/CustomTextField';
import { MedicalFileSelection } from 'Constants/MedicalFileSelection';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AddMedicalRecordSchema } from 'ValidationSchema/AddMedicalRecordSchema';

// image
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CommonTopHeader from 'ui-component/CommonTopHeader/CommonTopHeader';
import { useDispatch } from 'react-redux';
import { addErrorMessage, addSuccessMessage } from 'utils/LoadingContent';

import Files from 'react-files';
import { appMessages } from 'Constants/appMessage';
import CustomCard from 'components/CustomCard/CustomCard';
import axios from 'axios';
import CustomProgressBar from 'components/CustomProgressBar/CustomProgressBar';
import { authHeader } from 'utils/common';
import { getdata, postDataApi } from 'utils/apiCalling';
import { constants } from 'Constants/constants';

// icons
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CropSquareOutlinedIcon from '@mui/icons-material/CropSquareOutlined';
import reactDom from 'react-dom';
import { addMedicalRecord } from 'store/MedicalRecord/medicalRecordAction';
import { addRecord } from 'Constants/Images';
import { useTheme } from '@mui/material/styles';

export default function addMedicalRecordDesktop(props) {
    const { recordType, isFromQuickUpload = false } = props;
    // const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    // const cancelFileUpload = useRef(null);

    const [isAddRecord, setIsAddRecord] = useState(false);
    const [recordFile, setRecordFile] = useState(null);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [totalFile, setTotalFile] = useState([]);
    const [recordButtonEnable, setRecordButtonEnable] = useState(false);
    const [minimize, setMinimize] = useState(true);
    const [recordList, setRecordList] = useState([]);

    // this is for File Upload state start
    const [preservedURL, setPreservedURL] = useState([]);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);

    useEffect(() => {
        getRecordList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRecordList = async () => {
        const resp = await getdata(constants.gerAllRecordTypeList, true);
        if (resp.status === 200) {
            setRecordList(() => resp.data);
        }
    };

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const onFilesChange = (files) => {
        // addSuccessMessage('File Added');

        if (formik.values.recordType === '' && formik.values.recordName === '')
            addErrorMessage('Please select record type and record name  ');
        else if (formik.values.recordType === '') addErrorMessage('Please select record type');
        else if (formik.values.recordName === '') addErrorMessage('Please add record name');
        else {
            setRecordFile(files[0]);
            setTotalFile((prevState) => [...prevState, files[0]]);
            uploadData(files[0]);
        }
    };

    const onFilesError = (error, file) => {
        // console.log('error code ' + error.code + ': ' + error.message);
    };

    let addRecordSchema = {
        recordName: Yup.string().min(2, appMessages.tooShort).max(50, appMessages.tooLong).required('Record Name Required'),
        // recordNotes: Yup.string().min(2, appMessages.tooShort).max(50, appMessages.tooLong).required()
        recordDate: Yup.string().required('Date Required')
        // recordDate: Yup.date().required(),
    };

    const renderRecordSchema = () => {
        if (isFromQuickUpload) {
            return {
                ...addRecordSchema,
                emailAddress: Yup.string().email(appMessages.validEmail).required(appMessages.emailRequired)
            };
        } else {
            return addRecordSchema;
        }
    };

    let AddRecordSchema = Yup.object().shape(renderRecordSchema());

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formik = useFormik({
        initialValues: {
            recordType: recordType ?? '',
            recordName: '',
            emailAddress: '',
            recordNotes: '',
            recordDate: new Date().toISOString().slice(0, 10)
        },
        // handleReset: (values) => {

        // },
        validationSchema: AddMedicalRecordSchema,
        onSubmit: (values) => {
            saveChanges(values);
        }
    });

    // upload File
    const uploadData = async (recordItem) => {
        setRecordButtonEnable(true);
        let json = {
            // record_type: MedicalFileSelection.find((item) => item.key === formik.values.recordType)?.value,
            record_type: formik.values.recordType,
            file_name: recordItem.name,
            file_size: recordItem.size
        };

        if (isFromQuickUpload) {
            json = { file_name: recordItem.name };
        }

        const responseData = await postDataApi(
            isFromQuickUpload ? constants.quickUploadFileNameUpload : `${constants.baseURL}userrecord/get-presigned-url/`,
            json,
            true
        );

        if (responseData.status === 200 || responseData.status === 201) {
            setPreservedURL(responseData.data);
            sendNext(responseData.data, recordItem);
        } else {
            setRecordButtonEnable(false);
            setTotalFile(totalFile.slice(-1));
            addErrorMessage(`something went wrong ${responseData.response.data?.detail ?? ''}`);
        }
    };

    const sendNext = (response, recordItem) => {
        const finalData = isFromQuickUpload ? response.fields : response.data.fields;
        var formData = new FormData();
        formData.append('key', finalData.key);
        formData.append('x-amz-algorithm', `${finalData['x-amz-algorithm']}`);
        formData.append('x-amz-credential', `${finalData['x-amz-credential']}`);
        formData.append('x-amz-date', `${finalData['x-amz-date']}`);
        formData.append('policy', `${finalData['policy']}`);
        formData.append('x-amz-signature', finalData['x-amz-signature']);
        formData.append('file', recordItem);

        setIsAddRecord(!isAddRecord);
        axios
            .request({
                method: 'POST',
                url: isFromQuickUpload ? response.url : response.data.url,
                data: formData,
                headers: !isFromQuickUpload && { authHeader },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                    setFileUploadProgress(percent);
                }
            })
            // .post(isFromQuickUpload ? response.url : response.data.url, formData, !isFromQuickUpload && { authHeader })
            .then((_res) => {
                setUploadFiles((prevState) => [...prevState, finalData.key]);
                setRecordButtonEnable(false);
            })
            .catch((err) => {
                addErrorMessage('something went wrong');
                setRecordButtonEnable(false);
                setIsAddRecord(!isAddRecord);
                // if (isCancel(err)) {
                //     alert(err.message);
                // }
            });
    };

    const saveChanges = async (values) => {
        let json = {
            record_type: values.recordType,
            file_urls: recordFile === null ? [] : JSON.stringify(uploadFiles),
            record_date: values.recordDate,
            record_name: values.recordName,
            comments: values.recordNotes
        };
        if (isFromQuickUpload) {
            json = {
                email: values.emailAddress,
                record_type: selectMedicalType,
                file_urls: recordFile === null ? [] : JSON.stringify(uploadFiles),
                record_date: values.recordDate,
                record_name: values.recordName
            };
        }
        const responseData = await postDataApi(
            isFromQuickUpload ? constants.quickUploadSaveRecord : `${constants.baseURL}userrecord/save-userrecord/`,
            json,
            isFromQuickUpload ? false : true
        );
        if (responseData.status === 200 || responseData.status === 201) {
            addSuccessMessage('Record Added');
            !isFromQuickUpload && dispatch(addMedicalRecord(responseData.data.record));
            handleClose();
        } else {
            addErrorMessage('something went wrong');
        }
    };

    const handleClose = () => {
        setFileUploadProgress(0);
        reactDom.unmountComponentAtNode(document.getElementById('add-record'));
    };

    const handleMinimize = () => {
        // e.prevantDefault();
        setMinimize(!minimize);
    };

    const { errors, touched, setError, handleSubmit, getFieldProps } = formik;

    return (
        <Container
            maxWidth="lg"
            style={{
                position: 'fixed',
                right: 0,
                bottom: -26,
                maxWidth: '600px'
                // height: minimize ? '72vh' : '6rem'
            }}
        >
            <Paper style={{ border: '1px solid #D3EAFD' }}>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ background: (theme) => theme.palette.primary.main, color: 'white', height: '50px' }}
                >
                    <>
                        Add Medical Records
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 40,
                                top: 6,
                                color: 'white'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            onClick={handleMinimize}
                            sx={{
                                position: 'absolute',
                                right: 80,
                                top: 8,
                                color: 'white'
                            }}
                        >
                            {minimize === true ? <MinimizeIcon /> : <CropSquareOutlinedIcon />}
                        </IconButton>
                    </>
                </DialogTitle>
                <DialogContent
                    style={{
                        height: isMobile ? (minimize ? '72vh' : '1.9rem') : minimize ? '80vh' : '1.9rem',
                        maxHeight: isMobile ? (minimize ? '72vh' : '1.9rem') : minimize ? '70vh' : '1.9rem'
                    }}
                >
                    {minimize && (
                        <Box style={{ overflowX: 'auto' }}>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate>
                                    <Paper style={{ padding: '10px' }}>
                                        <Grid container spacing={2}>
                                            {/* <Grid item md={12} lg={12} sm={12} xs={12}>
                                        <CommonTopHeader headerName={'Add Medical Records'} isAddRecord headerImage={addRecord} />
                                    </Grid> */}

                                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    className="px-2 my-2"
                                                    variant="outlined"
                                                    label="Record Types"
                                                    required
                                                    select
                                                    value={''}
                                                    // onChange={handleChange}
                                                    {...getFieldProps('recordType')}
                                                    error={Boolean(touched.recordType && errors.recordType)}
                                                    helperText={touched.recordType && errors.recordType}
                                                >
                                                    {recordList.sort().map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.record_type}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                                <CustomTextField
                                                    label="Record Name"
                                                    placeholder="Enter name of your medical record"
                                                    required
                                                    {...getFieldProps('recordName')}
                                                    error={Boolean(touched.recordName && errors.recordName)}
                                                    helperText={touched.recordName && errors.recordName}
                                                />
                                            </Grid>
                                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                                <CustomTextField
                                                    type="date"
                                                    label="Record Date"
                                                    required
                                                    InputProps={{ inputProps: { max: new Date().toISOString().slice(0, 10) } }}
                                                    {...getFieldProps('recordDate')}
                                                    error={Boolean(touched.recordDate && errors.recordDate)}
                                                    helperText={touched.recordDate && errors.recordDate}
                                                />
                                            </Grid>
                                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                                <Box className="dis-flex-center">
                                                    <Box className="record-browse">
                                                        <Box className="dis-flex-center">
                                                            <CloudUploadIcon
                                                                fontSize="inherit"
                                                                style={{ fontSize: '50px' }}
                                                                htmlColor="#2196F3"
                                                            />
                                                            <Typography component={'span'} style={{ color: '#9C9CAA' }}>
                                                                Drag and drop<br></br> files to upload
                                                            </Typography>
                                                            <Files
                                                                className="files-dropzone"
                                                                onChange={onFilesChange}
                                                                onError={onFilesError}
                                                                minFileSize={0}
                                                                clickable
                                                            >
                                                                <Button variant="contained">Browse</Button>
                                                            </Files>
                                                        </Box>
                                                        <Box className="dis-flex-center "></Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {totalFile.length > 0 &&
                                                totalFile.map((item, index) => {
                                                    return (
                                                        <Grid item md={12} lg={12} sm={12} xs={12}>
                                                            <CustomCard
                                                                style={{
                                                                    padding: '10px 20px'
                                                                }}
                                                            >
                                                                <Box
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                        flexDirection: 'row'
                                                                    }}
                                                                >
                                                                    <Typography variant="h6">{item.name ?? ''}</Typography>
                                                                </Box>

                                                                <Grid item md={12} lg={12} sm={12} xs={12}>
                                                                    <CustomProgressBar value={fileUploadProgress} />
                                                                </Grid>
                                                            </CustomCard>
                                                        </Grid>
                                                    );
                                                })}
                                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                                <CustomTextField
                                                    label="Notes (optional)"
                                                    placeholder="Enter medical Notes"
                                                    multiline
                                                    minRows="3"
                                                    maxRows="4"
                                                    {...getFieldProps('recordNotes')}
                                                    error={Boolean(touched.recordNotes && errors.recordNotes)}
                                                    helperText={touched.recordNotes && errors.recordNotes}
                                                />
                                            </Grid>

                                            <Grid item md={12}>
                                                <Box className="dis-flex-center">
                                                    <Grid container spacing={2} textAlign="end" justifyContent={'end'}>
                                                        <Grid item md={4} lg={4} sm={12} xs={12}>
                                                            <Button
                                                                variant="contained"
                                                                fullWidth
                                                                disabled={recordButtonEnable}
                                                                onClick={() => !recordButtonEnable && handleSubmit()}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Form>
                            </FormikProvider>
                        </Box>
                    )}
                </DialogContent>
            </Paper>
        </Container>
    );
}
