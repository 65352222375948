// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    Typography,
    List,
    ListItem,
    Avatar,
    ListItemAvatar,
    ListItemText,
    LinearProgress
} from '@mui/material';
// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import WbCloudyIcon from '@mui/icons-material/WbCloudy';
import { useEffect, useState } from 'react';
import { constants } from 'Constants/constants';
import { getdata } from 'utils/apiCalling';
import CustomProgressBar from 'components/CustomProgressBar/CustomProgressBar';
import { useNavigate } from 'react-router';
import { textTransform } from '@mui/system';
// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        // border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [userPlanDetails, setUserPlanDetails] = useState(undefined);

    useEffect(() => {
        getUsedData();
    }, []);

    const getUsedData = async () => {
        const response = await getdata(constants.getUserPlanDetails);

        if (response.status === 200) {
            sessionStorage.setItem('userPlan', JSON.stringify(response.data));
            setUserPlanDetails(response.data);
        }
    };

    return (
        <CardStyle>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Upgrade your plan</Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: 0 }}>
                        <List sx={{ p: 0, m: 0 }}>
                            <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.primary.main,
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff'
                                        }}
                                    >
                                        <WbCloudyIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>

                                <Grid item style={{ paddingTop: 0, width: '100%' }}>
                                    <ListItemText
                                        sx={{ mt: 0, textTransform: 'uppercase' }}
                                        // primary={
                                        //     <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                        //         Get Extra Space
                                        //     </Typography>
                                        // }
                                        secondary={
                                            <Typography variant="caption">
                                                {userPlanDetails !== undefined && userPlanDetails.used_data}/
                                                {userPlanDetails !== undefined && userPlanDetails.total_data}
                                            </Typography>
                                        }
                                    />
                                    <CustomProgressBar
                                        value={userPlanDetails !== undefined ? Math.ceil(userPlanDetails.used_percent * 100) : 0}
                                    />
                                </Grid>
                                {/* <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                            Get Extra Space
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="caption">
                                            {userPlanDetails !== undefined && userPlanDetails.used_data}/
                                            {userPlanDetails !== undefined && userPlanDetails.total_data}
                                        </Typography>
                                    }
                                /> */}
                            </ListItem>
                        </List>
                    </Grid>
                    {/* <Grid item>
                    <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                        70% discount for 1 years <br />
                        subscriptions.
                    </Typography>
                </Grid> */}
                    {/* <Grid item style={{ paddingTop: 0 }}>
                        <CustomProgressBar value={userPlanDetails !== undefined && Math.ceil(userPlanDetails.used_percent)} />
                    </Grid> */}
                    <Grid item>
                        <Stack direction="row">
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() => navigate('view-medical-plan')}
                                    sx={{ boxShadow: 'none', marginTop: '-20px' }}
                                >
                                    Go Premium
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyle>
    );
};

export default UpgradePlanCard;
