export const appMessages = {
    appName: 'MdRx',
    loginSuccess: 'You are loggedin successfully!',
    logoutSuccess: 'You are loggedout successfully!',
    validEmail: 'Email must be a valid email address',
    validPin: 'Must Contain 4 Numeric Characters',
    validSharePin: 'Must Contain 5 Numeric Characters',
    validSharePinNew: 'Access code must contain 7 characters',
    validPassword: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    validPhone: 'Phone Number must be a valid Number',
    validOtp: 'OTP must be a valid Number',
    emailRequired: 'Email is required',
    pinRequired: 'Pin is required',
    passwordRequired: 'Pin is required',
    addressRequired: 'Address is required',
    companyRequired: 'Current Company is required',
    designationRequired: 'Current designation is required',
    phoneRegExp: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    passwordPinRegex: /^[0-9]{1,4}$/,
    passwordSharePinRegex: /^[0-9]{1,5}$/,
    passwordSharePinNewRegex: /^(?=.{7,})/,
    otpRegex: /^[0-9]{1,5}$/,
    firstNameRequired: 'First name required',
    lastNameRequired: 'Last name required',
    OtpRequired: 'OTP required',
    tooShort: 'Too Short!',
    tooLong: 'Too Long!',
    minsixLength: 'Minimum Six Length',
    otpResendSuccessfully: 'OTP Resend Successfully',
    errorOccuredResendOtp: 'Error Ocuured during Resend Otp',
    required: 'required',
    medicalHistory: {
        vitals: {
            recordDateRequired: 'Record date is required',
            recordTimeRequired: 'Record time is required',
            labelRequired: 'Label is required',
            temperatureRequired: 'Temperature is required',
            temperatureUnitRequired: 'Temperature Unit is required',
            SystolicRequired: 'Systolic pressure is required',
            DiastolicRequired: 'Diastolic pressure is required',
            bloodSugarRequired: 'Type is required',
            bloodSugarUnitRequired: 'Unit is required',
            bloodSugarLevelRequired: 'Blood Sugar Level is required',
            respiratoryRateRequired: 'Respiratory rate is required',
            oxygenRequired: 'Oxygen saturation is required',
            weightRequired: 'Weight is required',
            heightRequired: 'Height is required',
            genderRequired: 'Gender is required'
        },
        condition: {
            conditionRequired: 'Condition is required',
            onSetRequired: 'Onset is required',
            endDateRequired: 'End Date is Required'
        },
        allery: {
            allergyRequired: 'Allergy is required',
            descriptionRequired: 'Description is Required'
        }
    }
};
