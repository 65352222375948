import { Box } from '@mui/system';
import { AppStore, GooglePlay } from 'Constants/Images';
import React from 'react';

export default function DownloadApp() {
    return (
        <Box>
            <a href="https://apps.apple.com/us/app/mdrx/id1614686950" target={'_blank'}>
                <img src={AppStore} style={{ cursor: 'pointer' }} alt="AppStore"></img>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.mdrxonline" target={'_blank'}>
                <img src={GooglePlay} style={{ cursor: 'pointer' }} alt="GooglePlay"></img>
            </a>
        </Box>
    );
}
