/* eslint-disable react/prop-types */
import ReactDOM from 'react-dom';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function CustomSnackbar(props) {
    const [open, setClose] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        ReactDOM.unmountComponentAtNode(props.renderElement);
        setClose(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={handleClose}
            // TransitionProps={{ onExited: handleExited }}
        >
            <Alert severity={props.color}>{props.message}</Alert>
        </Snackbar>
    );
}
