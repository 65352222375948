export const MedicalFileSelection = [
    // {
    //     key: 1,
    //     value: 'x ray',
    //     isActive: false
    // },
    // {
    //     key: 2,
    //     value: 'brain scan',
    //     isActive: false
    // },
    // {
    //     key: 4,
    //     value: 'Electrocardiogram',
    //     isActive: false
    // },
    // {
    //     key: 5,
    //     value: 'Other',
    //     isActive: false
    // },
    // {
    //     key: 6,
    //     value: 'Personal Symptoms',
    //     isActive: false
    // },

    // {
    //     key: 7,
    //     value: 'Imaging',
    //     isActive: false
    // },
    // {
    //     key: 8,
    //     value: 'Pathology',
    //     isActive: false
    // },
    // {
    //     key: 9,
    //     value: 'Genomics',
    //     isActive: false
    // },
    // {
    //     key: 10,
    //     value: 'Dental',
    //     isActive: false
    // },
    // {
    //     key: 11,
    //     value: 'Vaccination',
    //     isActive: false
    // },
    {
        key: 3,
        value: 'Blood work',
        isActive: false
    },

    {
        key: 11,
        value: 'Lab Tests',
        isActive: false
    },
    {
        key: 12,
        value: 'Pathology',
        isActive: false
    },
    {
        key: 13,
        value: 'Radiology & Imaging',
        isActive: false
    },
    {
        key: 14,
        value: 'Prescriptions',
        isActive: false
    },
    {
        key: 15,
        value: 'Dental',
        isActive: false
    },
    {
        key: 16,
        value: 'Eye',
        isActive: false
    },
    {
        key: 17,
        value: 'Vaccinations',
        isActive: false
    },
    {
        key: 18,
        value: 'Genomics',
        isActive: false
    },
    {
        key: 19,
        value: 'Medical Notes',
        isActive: false
    },
    {
        key: 5,
        value: 'Other',
        isActive: false
    }
];
