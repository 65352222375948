// PaymentFailedPage.js
import { Card, makeStyles } from '@material-ui/core';
import { Button, CardContent, CardMedia, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { failed, success } from 'Constants/Images';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        textAlign: 'center'
    },
    card: {
        maxWidth: 600,
        margin: 'auto',
        textAlign: '-webkit-center'
    },
    media: {
        height: 200,
        width: 200
    },
    errorIcon: {
        fontSize: '4rem',
        color: theme.palette.error.main
    },
    message: {
        margin: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));

const PaymentFailedPage = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={failed} // Update with the path to your image
                    title="Payment Failed"
                />
                <CardContent>
                    <Typography variant="h2" color="error" className={classes.errorIcon}>
                        error
                    </Typography>
                    <Typography variant="h4" color="error" className={classes.message}>
                        Payment Failed
                    </Typography>
                    <Typography variant="body1">
                        Unfortunately, your payment could not be processed. Please try again later or contact customer support.
                    </Typography>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => navigate('/home')}>
                        Go to Home Page
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default PaymentFailedPage;
