const FooterRoutes = {
    id: 'FooterRoutes',
    title: 'Footer Routes',
    type: 'sibebarFalse',
    children: [
        {
            id: 'default1223s',
            title: 'GDPR Policy',
            type: 'item',
            url: '/app/gdpr-policy',
            breadcrumbs: false
        },
        {
            id: 'default1223',
            title: 'Privacy Policy',
            type: 'item',
            url: '/app/privacy-policy',
            breadcrumbs: false
        },
        {
            id: 'default1223',
            title: 'Terms of Use',
            type: 'item',
            url: '/app/terms-of-use',
            breadcrumbs: false
        }
    ]
};

export default FooterRoutes;
