import { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, applyMiddleware(reduxThunk));
const persister = 'Free';

export { store, persister };
