import React from 'react';
import { Card, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Container } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import {
    cloud_storage,
    home_carousel_0,
    home_carousel_1,
    home_carousel_2,
    home_carousel_3,
    home_carousel_4,
    secure
} from 'Constants/Images';
import { Box } from '@mui/system';
import {
    imageRender,
    useFeaturedContStyles,
    useFeaturedItemsStyles,
    useAboutUsParaTxtStyle,
    useAppDownloadContStyle,
    useAppDownloadTxtContStyle,
    useAppDownloadTxtStyle
} from 'views/pages/Home/home.style';
import CustomResponsiveCarousel from 'components/CustomCarousel/CustomResponsiveCarousel';
import { colors } from 'themes/colors';
import DownloadApp from 'views/pages/Home/DownloadApp';
import ViewReportLoginForm from 'components/authentication/login/ViewReportLoginForm';
import MedicalCollegeFooter from 'views/Footer/MedicalCollegeFooter/MedicalCollegeFooter';
// import MedicalCollegeFooter from 'views/Footer/MedicalCollegeFooter/MedicalCollegeFooter';

export default function ViewReportLogin() {
    const hidden = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const theme = useTheme();

    const styles = (theme) => ({
        textChange: {
            fontFamily: 'Gilroy-Bold',
            fontSize: '52px',
            lineHeight: '60px',
            color: '#151515',
            [theme.breakpoints.down('lg')]: {
                fontSize: '40px',
                lineHeight: '42px'
            }
        },
        textChange12: {
            fontFamily: 'Gilroy-Bold',
            fontSize: '32px',
            lineHeight: ' 52.73px',
            color: '#151515 ',
            [theme.breakpoints.down('lg')]: {
                fontSize: '2.5rem'
            }
        },
        leftPanel: {
            minheight: 'calc(100vh-100px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: '#151515'
        }
    });

    const HEADER_CAROUSEL = [
        {
            id: 1,
            headline: `Your entire family is connected whether by environment or genetically.Preserve your medical data to help yourself and those around you`,
            // .Maintain multiple health accounts and keep your data organized
            img: home_carousel_0
        },
        {
            id: 2,
            headline: 'Simple interface.Easy to use by Anybody, at Anytime, with  Any device, Anywhere.',
            img: home_carousel_1
        },
        {
            id: 3,
            headline: 'Your current data might contain clues for your future medical problems. Keep a record for future reference.',
            img: home_carousel_2
        },
        {
            id: 4,
            headline:
                'Your health has no boundaries and should have no boundaries.Share your medical data to any doctor and keep a record of their recommendations.',
            img: home_carousel_3
        },
        {
            id: 5,
            headline: 'Medical Data contain not only memories,but also potential life saving information.Preserve them forever.',
            img: home_carousel_4
        }
    ];

    // ==============================|| FEATURES ||============================== //
    const FEATURES = [
        {
            id: 1,
            heading: 'Security',
            detail: 'High end security protocols including end-to-end encryption, 2-factor authentication, and 128 bit SSL.',
            icon: secure
        },
        { id: 2, heading: 'Portablity', detail: 'Access your data anytime, anywhere, and on any device.', icon: cloud_storage },
        { id: 3, heading: 'Notes', detail: 'You can maintain notes on your medical records.', icon: secure },
        {
            id: 4,
            heading: 'Preserve',
            detail: 'Keep your raw data/imaging files along with other clinical data indefinitely.',
            icon: secure
        },
        { id: 5, heading: 'Sharing', detail: 'Share your medical records with ease and security.', icon: secure },
        {
            id: 6,
            heading: 'Empower',
            detail: 'You are not dependent on any hospital or institution. Nobody can access your records without your permission.',
            icon: secure
        }
    ];

    const useAboutUsTxtStyle = {
        '&.MuiTypography-root': {
            fontFamily: 'Gilroy-Bold',
            fontSize: '1.6rem',
            fontWeight: 900,
            color: colors.themeFont2
        }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();
    return (
        <div style={{ background: theme.palette.primary.light }}>
            {/* <Container maxWidth="lg"> */}
            <Grid container spacing={2}>
                {!hidden && (
                    <Grid
                        item
                        md={12}
                        lg={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                        }}
                    >
                        <Container maxWidth="xl">
                            <CustomResponsiveCarousel isAutoPlay={true}>
                                {HEADER_CAROUSEL.map((header_item, index) => (
                                    <div>
                                        <img src={header_item.img} alt="header" className="carousel-image" />
                                        {/* <Typography variant="h2" component="div" sx={useHeaderTxtStyleLogin}>
                                            {header_item.headline}
                                        </Typography> */}
                                    </div>
                                ))}
                            </CustomResponsiveCarousel>
                        </Container>
                    </Grid>
                )}

                <Grid item md={4} lg={4} sm={12} xs={12} className={!hidden && 'pos-absolute'}>
                    <Container maxWidth="xs">
                        <Card
                            style={{
                                boxShadow: '0px 12px 16px rgba(33, 150, 243, 0.1), 0px 4px 56px rgba(33, 150, 243, 0.1)',
                                borderRadius: '25px',
                                padding: '20px',
                                maxHeight: '80vh',
                                // height: !hidden ? '60vh' : '70vh',
                                borderColor: theme.palette.primary.main
                            }}
                        >
                            <ViewReportLoginForm />
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item md={12} lg={12}>
                    <Box sx={useFeaturedContStyles}>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{
                                '&.MuiTypography-root': {
                                    ...useAboutUsTxtStyle,
                                    position: 'relative',
                                    top: -25
                                }
                            }}
                        >
                            Features
                        </Typography>
                        <Grid container spacing={{ xs: 4, md: 5, lg: 7 }}>
                            {FEATURES.map((feature, index) => (
                                <Grid key={index} item xs={12} sm={12} md={4} lg={4}>
                                    <Box style={{ height: '12rem', position: 'relative' }} sx={useFeaturedItemsStyles}>
                                        <Stack flexDirection="row" alignItems="center">
                                            <Box
                                                sx={{
                                                    '&.MuiBox-root': {
                                                        background: 'rgba(14, 161, 215, .2)',
                                                        borderRadius: '55%',
                                                        padding: '.7rem',
                                                        height: '3rem',
                                                        width: '3rem',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }
                                                }}
                                            >
                                                <img
                                                    src={feature.icon}
                                                    style={{ width: '100%', imageRendering: imageRender }}
                                                    alt="features"
                                                />
                                            </Box>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                ml={2}
                                                sx={{
                                                    '&.MuiTypography-root': {
                                                        ...useAboutUsTxtStyle,
                                                        color: 'black',
                                                        fontSize: '17px !important'
                                                    }
                                                }}
                                            >
                                                {feature.heading}
                                            </Typography>
                                        </Stack>
                                        <Typography
                                            variant="p"
                                            component="div"
                                            mt={2}
                                            mb={2}
                                            sx={{
                                                '&.MuiTypography-root': {
                                                    ...useAboutUsParaTxtStyle,
                                                    fontSize: '16px !important',
                                                    color: '#6C87AE !important',
                                                    lineHeight: '1rem !important',
                                                    textAlign: 'center',
                                                    fontSize: '15px !important',
                                                    margin: '0 15px',
                                                    right: 0,
                                                    left: 0,
                                                    display: 'grid',
                                                    placeItems: 'center',
                                                    alignItems: 'center',
                                                    height: '100%'
                                                }
                                            }}
                                        >
                                            {feature.detail}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item md={12} lg={12}>
                    <Box sx={useAppDownloadContStyle}>
                        {/* <Grid container spacing={{ xs: 4, md: 6, lg: 12 }}>
                    <Grid item xs={12}> */}
                        <Box sx={useAppDownloadTxtContStyle}>
                            <Typography variant="h5" component="div" sx={useAppDownloadTxtStyle} style={{ marginTop: '4rem' }}>
                                DOWNLOAD APP ON IOS & ANDROID
                            </Typography>
                            <Typography
                                variant="h2"
                                component="div"
                                mt={1}
                                mb={1}
                                fontFamily="Gilroy-Bold"
                                color={colors.themeFont2}
                                fontSize={{ xs: '20px', sm: '25px', md: '25px', lg: '30px' }}
                            >
                                You should be able to access all your health information at any time. Now you can.
                            </Typography>
                        </Box>
                        <DownloadApp />
                    </Box>
                </Grid>
            </Grid>
            {/* </Container> */}
            <MedicalCollegeFooter />
        </div>
    );
}
