import LocalStorage from 'utils/LocalStorage';
import * as constant from './LoginUserTypes';

const initialState = {
    loading: false,
    userCookies:
        JSON.parse(sessionStorage.getItem('userSession')) !== null ? JSON.parse(sessionStorage.getItem('userSession')).cookies : [],
    userKey: JSON.parse(sessionStorage.getItem('userSession')) !== null ? JSON.parse(sessionStorage.getItem('userSession')).key : '',
    userDetails: LocalStorage.getUserDetails() ?? [],
    error: ''
};

const LoginUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.SET_USER_LOGIN_INFORMATION:
            return {
                ...state,
                userCookies: action.payload,
                userKey: action.payload,
                userDetails: action.payload
            };

        case constant.UPDATE_USER_INFORMATION:
            const data = action.payload;
            return {
                ...state,
                userDetails: { ...state.userDetails, ...data }
            };

        default:
            return state;
    }
};
export default LoginUserReducer;
