/* eslint-disable react/prop-types */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

const CustomConfirmationPopup = (props) => {
    const { title, message, isYes, isNo, yesName, noName, disableBackdropClick, disableEscapeKeyDown } = props;

    const handleNo = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('confirm-dialog'));
    };
    const handleYes = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('confirm-dialog'));
        props.handleAction();
    };

    return (
        <div>
            <Dialog open={true} onClose={handleNo} disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown}>
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        fontWeight="400px"
                        style={{ paddingTop: '20px', fontFamily: 'Gilroy-Bold', color: '#1D2130', fontStyle: 'normal' }}
                    >
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component="div" id="alert-dialog-description">
                        <Typography fontFamily={'Gilroy-Bold'} fontSize="14px">
                            {message}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} justifyContent="end">
                        {isYes && (
                            <Grid item md={4} lg={4} sm={4} xs={4}>
                                <Button variant={'contained'} style={{ borderRadius: '10px' }} fullWidth onClick={handleYes}>
                                    {yesName}
                                </Button>
                            </Grid>
                        )}

                        {isNo && (
                            <Grid item md={4} lg={4} sm={4} xs={4} marginRight={2}>
                                <Button
                                    variant={'contained'}
                                    style={{ borderRadius: '10px', background: '#D3EAFD', color: 'black' }}
                                    fullWidth
                                    onClick={handleNo}
                                >
                                    {noName}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};

CustomConfirmationPopup.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.any,
    isYes: PropTypes.bool,
    isNo: PropTypes.bool,
    yesName: PropTypes.string,
    noName: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool
};

export default CustomConfirmationPopup;
