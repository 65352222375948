import * as constant from './medicalRecordTypes';

const initialState = {
    loading: false,
    medicalRecord: [],
    error: ''
};

const medicalRecordReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.FETCH_MEDICAL_RECORD_REQUEST:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_MEDICAL_RECORD_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalRecord: action.payload,
                error: ''
            };

        case constant.FETCH_MEDICAL_RECORD_FAILURE:
            return {
                ...state,
                loading: false,
                medicalRecord: [],
                error: action.payload
            };

        case constant.ADD_NEW_MEDICAL_RECORD:
            state.medicalRecord.unshift(action.payload);
            return {
                ...state,
                medicalRecord: state.medicalRecord
            };

        case constant.DELETE_MEDICAL_RECORD_SUCCESS:
            return {
                ...state,
                medicalRecord: state.medicalRecord.filter((item) => item.slug !== action.payload)
            };
        case constant.DELETE_MULTIPLE_MEDICAL_RECORD_SUCCESS:
            return {
                ...state,
                medicalRecord: state.medicalRecord.filter((item) => !action.payload.includes(item.slug))
            };

        default:
            return state;
    }
};
export default medicalRecordReducer;
