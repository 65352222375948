const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/medical-record',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    // stripe public key
    PUBLIC_KEY: 'pk_test_51Kg0SnSBAXiwbxiCGFgA5nQmTmDRl3t19i4r0DRknm2luQLkRrUkFvl72JT3pYWO7y4yeoQ4o52TtEJvLTSlStRh00tUIa8FCl',
    ROLES: [
        { roleId: '9c535b2d-7a08-4879-a5da-c554216c63a2', roleName: 'PATIENT' },
        { roleId: 'c3045123-1548-4655-82b0-b9cd01b13dbe', roleName: 'DOCTOR' },
        { roleId: '50aca18e-0d73-43c0-875a-8eab6db5691c', roleName: 'SHARE' },
        { roleId: 'c0a9afe2-ef26-42d6-9291-b06b7b404971', roleName: 'INSTANT_SHARE' }
    ]
};

export default config;
